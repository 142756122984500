import React, { useEffect, useState } from 'react';
import Details from "./Details";
import Filters from "./Filters";
import MapOfNames from "./MapOfNames";
import TalentDevelopment from "./TalentDevelopment";
import TalentProfile from "./TalentProfile";
import TalentList from './TalentList';
import Admin from './Admin';

export default function CentralComponent(props) {

    const [mafList, setMafList] = useState([])
    const [selectedMaf, setSelectedMaf] = useState([])
    const [mifList, setMifList] = useState([])
    const [selectedMif, setSelectedMif] = useState([])
    const [compositionGradeList, setCompositionGradeList] = useState([])
    const [selectedComposition, setSelectedComposition] = useState([])
    const [keyPositionList, setKeyPositionList] = useState([])
    const [selectedKeyPos, setSelectedKeyPos] = useState([])
    const [selectedWorkdayId, setSelectedWorkdayId] = useState('')
    const [filters, setFilters] = useState({})
    const [onlyFilled, setOnlyFilled] = useState(false)
    const [selectedUsersId, setSelectedUsersId] = useState([])
    const [openFilter, setOpenFilter] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [detailFlag, setDetailFlag] = useState(false)


    return (
        <div className=" central_component_container" >
            {props.component === 'list' || props.component === 'map' || props.component === 'details' ?
                <Filters
                    mafList={mafList} setMafList={setMafList}
                    selectedMaf={selectedMaf} setSelectedMaf={setSelectedMaf}
                    mifList={mifList} setMifList={setMifList}
                    selectedMif={selectedMif} setSelectedMif={setSelectedMif}
                    compositionGradeList={compositionGradeList} setCompositionGradeList={setCompositionGradeList}
                    selectedComposition={selectedComposition} setSelectedComposition={setSelectedComposition}
                    keyPositionList={keyPositionList} setKeyPositionList={setKeyPositionList}
                    selectedKeyPos={selectedKeyPos} setSelectedKeyPos={setSelectedKeyPos}
                    filters={filters}
                    setFilters={setFilters}
                    onlyFilled={onlyFilled}
                    setOnlyFilled={setOnlyFilled}
                    openFilter={openFilter}
                    setOpenFilter={setOpenFilter}
                /> : ''}
            {props.component === 'list' ?
                <TalentList openFilter={openFilter} detailFlag={detailFlag} setDetailFlag={setDetailFlag} currentPage={currentPage} setCurrentPage={setCurrentPage} setComponent={props.setComponent} component={props.component} setSelectedWorkdayId={setSelectedWorkdayId} filters={filters} onlyFilled={onlyFilled} selectedUsersId={selectedUsersId} setSelectedUsersId={setSelectedUsersId} /> :
                props.component === 'profile' ?
                    <TalentProfile currentPage={currentPage} setComponent={props.setComponent} component={props.component} selectedWorkdayId={selectedWorkdayId} setSelectedWorkdayId={setSelectedWorkdayId} /> :
                    props.component === 'development' ?
                        <TalentDevelopment /> :
                        props.component === 'details' ?
                            <Details setComponent={props.setComponent} component={props.component} setSelectedWorkdayId={setSelectedWorkdayId} filters={filters} openFilter={openFilter} onlyFilled={onlyFilled} setOnlyFilled={setOnlyFilled} selectedUsersId={selectedUsersId} setSelectedUsersId={setSelectedUsersId} /> :
                            props.component === 'map' ?
                                <MapOfNames filters={filters} openFilter={openFilter} />
                                : props.component === 'admin' ?
                                    <Admin />
                                    : ''}
        </div>
    )
}