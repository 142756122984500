import React, { useEffect, useState } from "react";
import Select from 'react-select';
import Multiselect from 'multiselect-react-dropdown';

export default function SelectComponent(props) {

    const customStyles = {
        control: (base, state) => ({
            ...base,
            border: state.isFocused ? 0 : 0,
            // This line disable the blue border
            boxShadow: state.isFocused ? '0px 0px 1px 1px #000  !important' : 0,
            '&:hover': {
                border: state.isFocused ? '0px 0px 1px 1px #000  !important' : 0
            }
        }),
        option: (base, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...base,
                backgroundColor: isFocused ? "#000" : isSelected ? "#000" : 'white',
                ':active': {
                    backgroundColor: '#000',
                    color: "white"
                }
            };
        }
    };

    const handleChange = (e) => {
        props.setSelectedOption(e.value, props.field)
    }

    return (
        <Select
            value={{ label: (props.selectedOption ? props.selectedOption : <i>{props.optionalLabel}</i>) }}
            onChange={handleChange}
            options={props.options}
            className="right_tab_select"
            styles={customStyles}
        />
    )
}

export function InputComponent(props) {

    const handleChange = (e) => {
        props.setValue(e.target.value, props.field)
    }

    return (<input className="right_tab_input" value={props.value} onChange={handleChange}></input>)
}


export function MultiselectComponent(props) {

    const handleChange = (e) => {
        props.setField(e, props.field)
    }


    const multiselectStyle = {
        chips: {
            background: '#000',
            'font-size': '12px',
            'border-radius': '15px',
            'line-height': '14px',
            'margin-bottom': '1px'
        },
        multiselectContainer: {
            color: '#000',
        },
        searchBox: {
            border: 'none',
            'border-bottom': '1px solid #000',
            'border-radius': '0px',
            cursor: 'pointer', 'margin-top': '5px',
        }
    }

    let tmp = []
    if (props.prevSelected)
        props.prevSelected.map((n) => {
            tmp.push({ 'name': n })
        })

    return (
        <Multiselect
            options={props.options} // Options to display in the dropdown
            selectedValues={tmp} // Preselected value to persist in dropdown
            onSelect={(e) => handleChange(e)}
            onRemove={(e) => handleChange(e)}
            displayValue="name" // Property name to display in the dropdown options
            showCheckbox={true}
            avoidHighlightFirstOption={true}
            style={multiselectStyle}
            ref={props.multiselectRef}
            renderSelectedList={props.prevSelected}
        />
    )
}

