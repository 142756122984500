import React, { useEffect, useState } from 'react';
import { oktaAuth } from '../config';

export default function Navbar(props) {

    const [ddl, setDLL] = useState([{ id: '', label: 'Logout' }])

    useEffect(() => {
        window.onclick = function (event) {
            if (!event.target.matches('.dropbtn')) {
                document.getElementById("myDropdown").classList.remove("show")
                //var dropdowns = document.getElementsByClassName("dropdown-content");
                //var i;
                //for (i = 0; i < dropdowns.length; i++) {
                //    var openDropdown = dropdowns[i];
                //    if (openDropdown.classList.contains('show')) {
                //        //document.getElementById("myDropdown").classList.remove("show")
                //        openDropdown.classList.remove('show');
                //    }
                //}
            }
        }
    }, [])

    const myFunction = () => {
        document.getElementById("myDropdown").classList.toggle("show");
    }

    const logout = async () => {
        oktaAuth.signOut({ originalUri: "/" }).then(() => {
            sessionStorage.clear()
            localStorage.clear()
            window.location.reload()
        });
    }
    const handleChangeImpact = () => { }

    return (
        <div className="navbar_container flex">
            <div className="w-100">
                <div className="logo"></div>
                <div className="text">TALENT ASSESSMENT TOOL</div>
                <div className='flex'>
                    <div className='dropdown pointer' >
                        <div className="header_user">
                            <div className="dropbtn" onClick={() => myFunction()} >

                                <div id="myDropdown" class="dropdown-content-header">
                                    <div className="flex drop" onClick={() => logout()}>
                                        <div className="left_menu_text" Style={'align-self: center;'}>Logout</div>
                                    </div>
                                </div>
                            </div>
                            {props.user ? <div className="user_name user_icon" >
                                <div className='flex'>
                                    {props.user.first_name[0]}{props.user.last_name[0]}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="white" class="bi bi-caret-down-fill logout_arrow" viewBox="0 0 16 16">
                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                    </svg>
                                </div>
                            </div> : ''}

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}