import React, { useEffect, useState } from "react";
import { TalentService } from "../Services/Services";
import Select from 'react-select';
import toast, { Toaster } from 'react-hot-toast';
export default function Admin(props) {

    const [selected, setSelected] = useState('')
    const [previousImports, setPreviousImports] = useState([])
    const [file, setFile] = useState(null)
    const [xlsxfile, setXlsxFile] = useState(null)
    const [sections, setSections] = useState([])
    let menu_selected_class = "admin_menu admin_menu_selected"
    let menu_class = "admin_menu"
    let ts = new TalentService()
    useEffect(() => {
        ts.getImportTypes().then((data) => {
            let opt = []
            data.map((obj) => {
                opt.push({
                    label: obj.file_type,
                    value: obj.id
                })
            })
            setSections(opt)
        })
        ts.getLastImports().then((data) => {
            setPreviousImports(data)
        })
    }, [])

    const handleFile = (e, xlsxfile) => {
        if (e && e.target && e.target.value) {
            setFile(e)
            setXlsxFile(xlsxfile)

        }
    }
    const upload = () => {
        let tmp = file.target.value.split('.')
        let ext = tmp[tmp.length - 1]
        if (ext === 'xlsx') {

            let fd = new FormData()
            fd.append("file", xlsxfile)
            ts.uploadFile(fd, selected.label).then((response) => {
                toast.success(response.message)
                file.target.value = ''
                setFile(null)
                setXlsxFile(null)
                setSelected('')
            }).catch((err) => {
                toast.error(err.response.data.message)
            })

        } else {
            toast.error('You can upload only xlsx file!')
        }
    }
    const getFilename = () => {
        let tmp = file.target.value.split('\\')
        return tmp[tmp.length - 1]
    }
    const clear = () => {
        file.target.value = null
        setFile(null)
    }

    let importList = ''
    if (previousImports && previousImports.length > 0)
        importList = previousImports.map((file) => <li class="table-row">
            <div class="col-xl col-1" data-label="File Type">{file.file_type ? file.file_type : '-'}</div>
            <div class="col-xl col-2" data-label="File Name">{file.file_name ? file.file_name : '-'}</div>
            <div class="col-xl col-3" data-label="Imported At">{file.imported_at ? file.imported_at : '-'}</div>
            <div class="col-xl col-4" data-label="Imported by">{file.imported_by ? file.imported_by : '-'}</div>
        </li>)
    const customStyles = {
        control: (base, state) => ({
            ...base,
            border: state.isFocused ? 0 : 0,
            // This line disable the blue border
            boxShadow: state.isFocused ? '0px 0px 1px 1px #000  !important' : 0,
            '&:hover': {
                border: state.isFocused ? '0px 0px 1px 1px #000  !important' : 0
            }
        }),
        option: (base, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...base,
                backgroundColor: isFocused ? "#000" : isSelected ? "#000" : 'white',
                color: isFocused ? "#fff" : '#000',
                ':active': {
                    backgroundColor: '#000',
                    color: "white"
                },
            };
        }
    };
    return (
        <React.Fragment >
            <div className="grid w-100">

                <h2> File import </h2>
                <div className="flex mt-30">

                    <div className="">

                        <Select
                            value={selected}
                            onChange={setSelected}
                            options={sections}
                            className="admin_select"
                            /* theme={(theme) => ({
                                 ...theme,
                                 borderRadius: 0,
                                 option: (provided, state) => ({
                                     ...provided,
                                     backgroundColor: state.isSelected ? "rgba(189,197,209,.3)" : "white",
                                 }),
                                 colors: {
                                     ...theme.colors,
                                     text: 'black',
                                     primary25: '#000b8',
                                     primary: '#000',
                                     backgroundColor: '#000b8', neutral80: 'black',
                                 },
                             })}
 */
                            styles={customStyles}
                        />
                    </div>
                    <label className={file || selected === '' ? "upload_label_disabled" : "upload_label"} for="upload-photo">Choose file</label>
                    <input disabled={file || selected === '' ? true : false} hidden={true} type="file" name="photo" id="upload-photo" className={file || selected === '' ? "upload_label_disabled" : "upload_label"} onChange={(e) => handleFile(e, e.target.files[0])} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                    <button className={file ? "upload_button" : "upload_button_disabled"} disabled={file ? false : true} onClick={() => upload()}> Upload</button>
                </div>
                <div className="flex mt-10">
                    <div className="ml-50 ">
                        {file ? getFilename() : ''}
                    </div>
                    {file ? <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#000" class="bi bi-x pointer" viewBox="0 0 16 16" onClick={() => clear(null)}>
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                    </svg> : ''}
                </div>
                {previousImports && previousImports.length > 0 ?



                    <div class="admin_imports_table mt-30">
                        <h2>Uploaded files </h2>
                        <ul class="responsive-table responsive-table-profile">
                            <li class="table-header">
                                <div class="col-xl col-1">File Type</div>
                                <div class="col-xl col-2">File Name</div>
                                <div class="col-xl col-3">Imported at</div>
                                <div class="col-xl col-4">Imported by</div>
                            </li>
                            <div Style='height: calc(100vh - 350px);    overflow: auto;    padding-top: 4px;' >
                                {importList}
                            </div>
                        </ul>
                    </div> : ''}
            </div>
            <Toaster
                position="bottom-right"
                toastOptions={{
                    success: {
                        style: {
                            background: 'green',
                            color: 'white'
                        },
                        iconTheme: {
                            primary: 'green',
                            secondary: 'white',
                        },
                        duration: 3000
                    },
                    error: {
                        style: {
                            background: '#000',
                            color: 'white'
                        },
                        iconTheme: {
                            primary: '#000',
                            secondary: 'white',
                        },
                        duration: 3000
                    },
                }}
            />
        </React.Fragment>
    )
}