export default function TalentDevelopment(props) {


    return (
        <div className="grid w-100">

            <h2> Talent Development </h2>

        </div>
    )
}