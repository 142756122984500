import React, { useEffect, useState } from "react"
import { TalentService } from "../Services/Services"
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Select from 'react-select';
export default function TalentProfile(props) {

    const [isLoading, setLoading] = useState(true)
    const [talentService] = useState(new TalentService())
    const [usersOptions, setUsersOptions] = useState([])
    const [workerInfo, setWorkerInfo] = useState([])
    const [career, setCareer] = useState([])
    const [selectedOption, setSelectedOption] = useState('')
    const [pastDevelopmentActionInfo, setPastDevelopmentActionInfo] = useState([])
    const [nextDevelopmentActionInfo, setNextDevelopmentActionInfo] = useState([])
    const [glint360Report, setGlint360Report] = useState([])
    const [exporting, setExporting] = useState(false)

    useEffect(() => {
        if (props.selectedWorkdayId)
            talentService.getTalentProfile(props.selectedWorkdayId).then((data) => {
                setWorkerInfo(data.workers_info)
                setCareer(data.career)
                setPastDevelopmentActionInfo(data.past_development_actions)
                setNextDevelopmentActionInfo(data.next_development_actions)
                setGlint360Report(data.glint360_export)
            })

    }, [props.selectedWorkdayId])
    useEffect(() => {
        if (selectedOption !== '') {
            setLoading(true)
            talentService.getTalentProfile(selectedOption.value).then((data) => {
                setWorkerInfo(data.workers_info)
                setCareer(data.career)
                setPastDevelopmentActionInfo(data.past_development_actions)
                setNextDevelopmentActionInfo(data.next_development_actions)
                setSelectedOption('')
                setUsersOptions([])
                setLoading(false)
            })
        }
    }, [selectedOption])

    let careerPreferences = '-'
    if (career && career.career_preferences) {
        careerPreferences = career.career_preferences.map((cp) =>
            <div>{cp}</div>
        )
    }
    let pastDevelopmentAction = ''
    if (pastDevelopmentActionInfo)
        pastDevelopmentAction = pastDevelopmentActionInfo.map((p) => <tr><td>{p.title}</td><td>{p.year}</td></tr>)

    let verticalTimeline = ''
    if (workerInfo && workerInfo.business_title_history)
        verticalTimeline = workerInfo.business_title_history.map((t) =>
            <VerticalTimelineElement
                className="vertical-timeline-element--work test"
                contentStyle={{ color: '#000', marginLeft: '30px' }}
                contentArrowStyle={{ display: 'none' }}
                date={t.year}
                iconStyle={{ background: '#000', color: '#fff', marginLeft: '-32px; !important' }}
                position={'right'}
                iconClassName={'vertical-timeline-icon-class'}
            >
                <div className="vertical-timeline-element-title bold">{t.layer}</div>
            </VerticalTimelineElement>
        )
    var searchtimer = ''
    const searchUser = (e) => {
        clearTimeout(searchtimer);
        searchtimer = setTimeout(() => {
            if (e !== '' & e.length > 0) {
                talentService.searchUser(e).then((res) => {
                    let tmp = []
                    res.map((u) => {
                        tmp.push({
                            label: u.worker_name,
                            value: u.workday_id
                        })
                    })
                    setUsersOptions(tmp)
                })
            }
        }, 1500);
    }
    const customStyles = {
        control: (base, state) => ({
            ...base,
            minWidth: 200,
            cursor: 'pointer',
            border: state.isFocused ? 0 : 0,
            // This line disable the blue border
            boxShadow: state.isFocused ? '0px 0px 1px 1px #white  !important' : 0,
            '&:hover': {
                border: state.isFocused ? '0px 0px 1px 1px #000  !important' : 0
            }
        }),
        option: (base, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...base,
                backgroundColor: isFocused ? "#000" : isSelected ? "#fff" : '#fff',
                color: isFocused ? "#fff" : 'black',
                ':active': {
                    backgroundColor: '#000',
                    color: "white"
                }
            };
        }
    };
    const selectOption = (e) => {
        console.log(e)
        props.setSelectedWorkdayId(e.value)
        setSelectedOption(e)
    }
    const NoOptionsMessage = props => {
        return (
            <span className="no_options">No Options</span>
        );
    };

    const hadleExport = () => {
        if (!exporting) {
            setExporting(true)
            talentService.exportTalentProfile(workerInfo.workday_id, workerInfo.worker_name).finally(() => {
                setExporting(false)
            })
        }
    }
    //user column padding
    let padding = (((window.innerWidth) / 100) - 350) / 2
    let column_dim = (window.innerWidth - 370 - 50) / 2
    return (
        <div className="talent_profile grid w-100 mt-50">

            <div className="flex" Style={'justify-self: right;    padding-right: 20px;'}>

            </div>
            <h3 className="back_button" onClick={() => props.setComponent('list')} Style={'width:fit-content'}>    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" fill="currentColor" class="bi bi-chevron-left " viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0" />
            </svg>
                Back to list </h3>
            <div>
                <div className="export_buttons_container flex" Style={'margin-top:-30px'}>



                    <Select
                        value={{ label: (selectedOption && selectedOption.label ? selectedOption.label : <i>Search User</i>) }}
                        onInputChange={(e) => searchUser(e)}
                        onChange={(e) => selectOption(e)}
                        options={usersOptions}
                        className="right_tab_buttons_select profile_select"
                        styles={customStyles}
                    //  components={{ NoOptionsMessage }}

                    />
                    {props.selectedWorkdayId ?

                        <div className={!exporting ? "flex export_button mr-10" : "flex export_button_disabled mr-10"} onClick={() => hadleExport()} >
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#000" class="bi bi-filetype-pdf" viewBox="0 0 16 16" >
                                    <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z" />
                                </svg>
                            </div>
                        </div>

                        : ''}

                </div>
            </div>
            {props.selectedWorkdayId ?
                <div>

                    <div className="flex">
                        <div className="" Style={'padding:20px 10px; width:350px;'}>
                            <div Style={'width:370px; padding: 0px ' + padding + 'px '}>

                                <div className="center">
                                    <div className="profile_picture_right_tab"></div>
                                </div>
                                <div className="right_tab_title mt-10">{workerInfo.worker_name}</div>
                                {/*<div className="right_tab_subtitle">{workerInfo.talent_pool}</div> */}
                                <div className="right_tab_container_profile">
                                    <div className="right_tab_info_container">
                                        <div className="right_tab_info_row">
                                            <div className="bold right_tab_section_name">Worker's info</div>
                                            <div className="flex">
                                                <div className="bold right_tab_category_name mr-5">Function:</div>
                                                <div className="book right_tab_value_name">{workerInfo.business_title ?? '-'}</div>
                                            </div>
                                            <div className="flex">
                                                <div className="bold right_tab_category_name mr-5">Region:</div>
                                                <div className="book right_tab_value_name">{workerInfo.region ?? '-'}</div>
                                            </div>
                                            <div className="flex">
                                                <div className="bold right_tab_category_name mr-5">Country:</div>
                                                <div className="book right_tab_value_name">{workerInfo.country ?? '-'} </div>
                                            </div>
                                            <div className="flex">
                                                <div className="bold right_tab_category_name mr-5">Manager:</div>
                                                <div className="book right_tab_value_name">{workerInfo.region ?? '-'} </div>
                                            </div>
                                            <div className="flex">
                                                <div className="bold right_tab_category_name mr-5">Span of control:</div>
                                                <div className="book right_tab_value_name">{workerInfo.span_of_control ?? '-'} </div>
                                            </div>
                                            <div className="flex">
                                                <div className="bold right_tab_category_name mr-5">Layer:</div>
                                                <div className="book right_tab_value_name">{workerInfo.layer ?? '-'} </div>
                                            </div>
                                            <div className="flex">
                                                <div className="bold right_tab_category_name mr-5">Time in position:</div>
                                                <div className="book right_tab_value_name">{workerInfo.time_in_position ?? '-'} </div>
                                            </div>
                                            <div className="flex">
                                                <div className="bold right_tab_category_name mr-5">Time in kering:</div>
                                                <div className="book right_tab_value_name">{workerInfo.time_in_kering ?? '-'} </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="right_tab_timeline_container">
                                        <VerticalTimeline
                                            lineColor={'#000'}
                                            className={"test2"}
                                        //layout={'1-column-right'}
                                        >
                                            {verticalTimeline}
                                        </VerticalTimeline>
                                    </div>
                                    <div className="right_tab_info_container">
                                        <div className="right_tab_info_row">
                                            <div className="flex">
                                                <div className="bold right_tab_category_name mr-5">Impact of leaving:</div>
                                                <div className="book right_tab_value_name">
                                                    {workerInfo.impact_of_leaving == 'LOW' ?
                                                        <React.Fragment>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="#000" class="bi bi-circle-fill" viewBox="0 0 16 16">
                                                                <circle cx="8" cy="8" r="8" />
                                                            </svg><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-circle" viewBox="0 0 16 16">
                                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                            </svg>
                                                        </React.Fragment>
                                                        :
                                                        <React.Fragment>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="#000" class="bi bi-circle-fill" viewBox="0 0 16 16">
                                                                <circle cx="8" cy="8" r="8" />
                                                            </svg><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="#000" class="bi bi-circle-fill" viewBox="0 0 16 16">
                                                                <circle cx="8" cy="8" r="8" />
                                                            </svg>
                                                        </React.Fragment>
                                                    }</div>
                                            </div>
                                            <div className="flex">
                                                <div className="bold right_tab_category_name mr-5">Risk of leaving:</div>
                                                <div className="book right_tab_value_name">{workerInfo.risk_of_leaving == 'LOW' ?
                                                    <React.Fragment>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="#000" class="bi bi-circle-fill" viewBox="0 0 16 16">
                                                            <circle cx="8" cy="8" r="8" />
                                                        </svg><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-circle" viewBox="0 0 16 16">
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                        </svg>
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="#000" class="bi bi-circle-fill" viewBox="0 0 16 16">
                                                            <circle cx="8" cy="8" r="8" />
                                                        </svg><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="#000" class="bi bi-circle-fill" viewBox="0 0 16 16">
                                                            <circle cx="8" cy="8" r="8" />
                                                        </svg>
                                                    </React.Fragment>
                                                } </div>
                                            </div>
                                            <div className="flex mt-10">
                                                <div className="bold right_tab_category_name mr-5">Talent Assestment 2022:</div>
                                                <div className="book ">{workerInfo.talent_assestment ?? '-'} </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div>

                            <div className="profile_title" Style={"margin-left: 45px;"}>{workerInfo.talent_pool} {workerInfo.talent_pool && workerInfo.development_strategy ? 'to' : ''} {workerInfo.development_strategy}</div>
                            <div className="flex">
                                <div className="profile_column" Style={'width:' + column_dim + 'px; padding-left:25px'}>
                                    <div className="profile_card">
                                        <div className="profile_title">Key Strenghts</div>
                                        {workerInfo.key_strenghts ? <div className="profile_text">{workerInfo.key_strenghts}</div> : '-'}
                                    </div>

                                    <div className="profile_card">
                                        <div className="profile_title">Potential Next Role:</div>
                                        {workerInfo.potential_next_role ? <div className="profile_text">{workerInfo.potential_next_role}</div> : '-'}
                                        <div className="profile_title">Potential Successor:</div>
                                        {workerInfo.potential_successors ? <div className="profile_text">{workerInfo.potential_successors}</div> : '-'}
                                    </div>
                                    {/* 
                            <div className="profile_card">
                                <div className="profile_title">Talent Pool:</div>
                                {workerInfo.talent_pool ? <div className="profile_text">{workerInfo.talent_pool}</div> : '-'}
                                <div className="profile_title">Development Strategy:</div>
                                {workerInfo.development_strategy ? <div className="profile_text">{workerInfo.development_strategy}</div> : '-'}
                            </div>*/}

                                    <div className="profile_card">
                                        <div className="profile_title">Career Interest </div>
                                        {workerInfo.talecareer_interestnt_pool ? <div className="profile_text">{workerInfo.career_interest}</div> : '-'}
                                    </div>

                                    <div className="profile_card">
                                        <div className="profile_title">Past Development Action And Talent Program<div Style={'text-transform:lowercase'}>(last 3 years)</div> </div>

                                        {!pastDevelopmentActionInfo || pastDevelopmentActionInfo.length == 0 ? '-' : <table className="profile_table">
                                            <tr className="thead">
                                                <th>TITLE</th>
                                                <th>YEAR</th>
                                            </tr>
                                            {pastDevelopmentAction}
                                        </table>
                                        }
                                    </div>
                                    {/*

                        <div className="profile_card">
                            <div className="profile_title">Glint 360 Report</div>
                            <div className="profile_text"> Average of All But Self: {glint360Report && glint360Report.avg_all_but_self ? glint360Report.avg_all_but_self : ' -'}</div>
                            <div className="profile_text">Average of Manager: {glint360Report && glint360Report.avg_directs ? glint360Report.avg_directs : ' -'}</div>
                            <div className="profile_text"> Average of Directs: {glint360Report && glint360Report.avg_manager ? glint360Report.avg_manager : ' -'}</div>
                            <div className="profile_text">Average of Other:  {glint360Report && glint360Report.avg_other ? glint360Report.avg_other : ' -'}</div>
                        </div>
                        */}
                                </div>
                                <div className="profile_column" Style={'width:' + column_dim + 'px'}>
                                    <div className="profile_card">
                                        <div className="profile_title">Key Development areas</div>
                                        {workerInfo.key_development_areas ? <div className="profile_text">{workerInfo.key_development_areas}</div> : '-'}
                                    </div>

                                    <div className="profile_card">
                                        <div className="profile_title">Relocation preferences</div>
                                        <div className="profile_text"> Short Term: {career && career.open_to_short_term_relocation ? career.open_to_short_term_relocation : '-'}</div>
                                        <div className="profile_text">Where:  {career && career.short_term_relocation_areas ? career.short_term_relocation_areas : '-'}</div>
                                        <div className="profile_text"> Long Term: {career && career.open_to_long_term_relocation ? career.open_to_long_term_relocation : '-'}</div>
                                        <div className="profile_text">Where:  {career && career.long_term_relocation_areas ? career.long_term_relocation_areas : '-'}</div>
                                    </div>

                                    <div className="profile_card">
                                        <div className="profile_title">Career preferences</div>
                                        {careerPreferences}
                                    </div>
                                    <div className="profile_card">
                                        <div className="profile_title">Open to internal opportunities?</div>
                                        {career && career.open_to_internal_opportunities ? <div className="profile_text">{career.open_to_internal_opportunities}</div> : '-'}
                                    </div>


                                    <div className="profile_card">
                                        <div className="profile_title">Proposed development action Employee/Manager</div>
                                        {(!nextDevelopmentActionInfo) || nextDevelopmentActionInfo && nextDevelopmentActionInfo.length == 0 ? '-' :
                                            <table className="profile_table">
                                                <tr className="thead">
                                                    <th colspan={4} className="center">EMPLOYEE</th>
                                                </tr>
                                                <tr>
                                                    <td>{nextDevelopmentActionInfo && nextDevelopmentActionInfo.dev_actions1_employee_rating ? nextDevelopmentActionInfo.dev_actions1_employee_rating : ''}</td>
                                                    <td>{nextDevelopmentActionInfo && nextDevelopmentActionInfo.dev_actions2_employee_rating ? nextDevelopmentActionInfo.dev_actions2_employee_rating : ''}</td>
                                                </tr>
                                                <tr>
                                                    <td>{nextDevelopmentActionInfo && nextDevelopmentActionInfo.dev_actions1_employee_comment ? nextDevelopmentActionInfo.dev_actions1_employee_comment : ''}</td>
                                                    <td>{nextDevelopmentActionInfo && nextDevelopmentActionInfo.dev_actions2_employee_comment ? nextDevelopmentActionInfo.dev_actions2_employee_comment : ''}</td>
                                                </tr>
                                                <tr className="thead" >
                                                    <th colspan={4} className="center">MANAGER</th>
                                                </tr>
                                                <tr><td>{nextDevelopmentActionInfo && nextDevelopmentActionInfo.dev_actions1_manager_rating ? nextDevelopmentActionInfo.dev_actions1_manager_rating : ''}</td>
                                                    <td>{nextDevelopmentActionInfo && nextDevelopmentActionInfo.dev_actions2_manager_rating ? nextDevelopmentActionInfo.dev_actions2_manager_rating : ''}</td>
                                                </tr>
                                                <tr><td>{nextDevelopmentActionInfo && nextDevelopmentActionInfo.dev_actions1_manager_comment ? nextDevelopmentActionInfo.dev_actions1_manager_comment : ''}</td>
                                                    <td>{nextDevelopmentActionInfo && nextDevelopmentActionInfo.dev_actions2_manager_comment ? nextDevelopmentActionInfo.dev_actions2_manager_comment : ''}</td>
                                                </tr>
                                            </table>}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                :
                <div className="center">
                    <div className='not_selected'>Please select the user to see the details.</div>
                </div>}


        </div >)
}
