import React, { useEffect, useState } from "react"
import { TalentService } from "../Services/Services"
import { BarLoader } from "react-spinners";

export default function Details(props) {
    const [countMatrix, setCountMatrix] = useState(null)
    const [ageMatrix, setAgeMatrix] = useState(null)
    const [genderMatrix, setGenderMatrix] = useState(null)
    const [seniorityMatrix, setSeniorityMatrix] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        let ts = new TalentService()
        let tmpObj = props.filters
        tmpObj['only_filled'] = props.onlyFilled
        if (props.filters['employee'])
            tmpObj['employee'] = props.filters['employee'].value
        ts.getFilteredMatrix(tmpObj).then((data) => {
            if (data.count_matrix)
                setCountMatrix(data.count_matrix)
            if (data.age_matrix)
                setAgeMatrix(data.age_matrix)
            if (data.gender_matrix)
                setGenderMatrix(data.gender_matrix)
            if (data.seniority_matrix)
                setSeniorityMatrix(data.seniority_matrix)
            setLoading(false)
        })
    }, [props.filters, props.onlyFilled])

    //get count matrix (custom)
    const getCountMatrix = () => {

        let first_subcategories = ''
        let second_subcategories = ''

        if (countMatrix && countMatrix.columns) {
            first_subcategories = countMatrix.columns.subcategories.map((s, index) =>
                <th Style={"background-color: " + s.bg_color + "; color:" + s.color + ";"} colSpan={2} > {s.name}</th>)

            second_subcategories = countMatrix.rows.subcategories.map((s, i) =>
                <tr>
                    {i == 0 ?
                        <td className='col-rotate' rowspan="4"><div Style={'text-transform: uppercase;transform: rotatez(270deg);  max-width: 110px;background-color: ' + countMatrix.rows.bg_color + '; color:' + countMatrix.rows.color}>{countMatrix.rows.name}</div></td>
                        : ''}
                    <td className='' Style={"background-color: " + s.bg_color + "; color: " + s.color + ";box-shadow: 0px 0px 2px black;"} colSpan={2}>{s.name}</td>
                    {countMatrix.matrix ? countMatrix.matrix[i].map((el, i2) =>
                        <React.Fragment>
                            <td className="col-s p-7" Style={"background-color: " + el.bg_color + "; color:" + el.color + ";text-align: justify; min-width:100px;box-shadow: 0px 0px 2px black;"} colSpan={!el.percentage ? 2 : 1}>
                                {el.value ?? '-'}
                            </td>
                            {!el.percentage ? '' : <td className="col-s p-7" Style={"background-color: " + el.bg_color + "; color:" + el.color + ";text-align: justify; min-width:100px;box-shadow: 0px 0px 2px black;"}>
                                {el.percentage}
                            </td>}
                        </React.Fragment>
                    ) : ''}
                </tr>)
        }

        let count_matrix = ''
        if (countMatrix && countMatrix.columns)
            count_matrix = <table className='details_table bold' >
                <tr>
                    <th className='empty_cell' colspan="5" rowspan="2"></th>
                    <th className='first_parameter' colspan="6" Style={'background-color: ' + countMatrix.columns.bg_color + '; color:' + countMatrix.columns.color}>{countMatrix.columns.name}</th>
                </tr>
                <tr>{first_subcategories} </tr>
                {second_subcategories}
            </table>

        return count_matrix
    }

    //get matrix with a sigle value
    const getSingleValueMatrix = (matrixInfo) => {

        let first_subcategories = ''
        let second_subcategories = ''

        if (matrixInfo && matrixInfo.columns) {
            first_subcategories = matrixInfo.columns.map((s, index) =>
                <React.Fragment>
                    {index == 0 ? <th Style={"background-color: " + s.bg_color + "; color:" + s.color + ";"} >{matrixInfo.name}</th> : ''}
                    < th Style={"background-color: " + s.bg_color + "; color:" + s.color + "; box-shadow: 0px 0px 2px black;"}  > {s.name}</th>
                </React.Fragment>
            )
            second_subcategories = matrixInfo.rows.map((s, i) =>
                <tr>
                    <td className='' Style={"background-color: " + s.bg_color + "; color: " + s.color + ";box-shadow: 0px 0px 2px black;"} >{s.name}</td>
                    {matrixInfo.matrix[i].map((el, i2) =>
                        <td className="col-s p-5" Style={"background-color: " + el.bg_color + "; color:" + el.color + ";text-align: justify; min-width:100px;box-shadow: 0px 0px 2px black;"} >
                            {el.value ?? ''}
                        </td>
                    )}
                </tr>)
        }

        let age_matrix = ''
        if (matrixInfo && matrixInfo.columns)
            age_matrix = <table className='details_table bold'>
                <tr>{first_subcategories}</tr>
                {second_subcategories}
            </table>

        return age_matrix
    }

    //get matrix with two values
    const getDoubleValueMatrix = (matrixInfo) => {

        let first_subcategories = ''
        let second_subcategories = ''

        if (matrixInfo && matrixInfo.columns) {
            first_subcategories = matrixInfo.columns.map((s, index) =>
                <React.Fragment>
                    {index == 0 ? <th Style={"background-color: " + s.bg_color + "; color:" + s.color + ";"} colSpan={2} >{matrixInfo.name}</th> : ''}
                    <th className='col-s' Style={"box-shadow: 0px 0px 2px black;background-color: " + s.bg_color + "; color:" + s.color + ";"}  > {s.name}</th >
                </React.Fragment>)

            second_subcategories = matrixInfo.rows.map((s, i) =>
                <tr>
                    <td className='col-gender' Style={"background-color: " + s.bg_color + "; color: " + s.color + ";box-shadow: 0px 0px 2px black;"}  >{s.name}</td>
                    {matrixInfo.matrix[i].map((el) =>
                        <td className="col-s-gender p-7" Style={"background-color: " + el.bg_color + "; color:" + el.color + ";text-align: justify; min-width:100px;box-shadow: 0px 0px 2px black;"} >
                            <div> {el.female ?? ''} </div>
                            <div>{el.male ?? ''}</div>
                        </td>
                    )}
                </tr>)
        }

        let gender_matrix = ''
        if (matrixInfo && matrixInfo.columns)
            gender_matrix = <table className='details_table bold'>
                <tr>{first_subcategories}</tr>
                {second_subcategories}
            </table>

        return gender_matrix
    }

    return (
        <div className="grid w-100">
            <h2 className="mt-30"> Talent Details </h2>
            {loading ?
                <div Style={" text-align: -webkit-center; margin-top: 10%;"}>
                    <BarLoader color="#000" />
                </div>
                :
                <div className='detail_matrix' Style={"margin-bottom:100px; "}>
                    {countMatrix ? getCountMatrix() : ''}
                    {ageMatrix ? getSingleValueMatrix(ageMatrix) : ''}
                    {seniorityMatrix ? getSingleValueMatrix(seniorityMatrix) : ''}
                    {genderMatrix ? getDoubleValueMatrix(genderMatrix) : ''}
                </div>
            }
        </div>
    )
}