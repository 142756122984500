import { Route, Routes, useNavigate } from "react-router-dom";
import { Security } from "@okta/okta-react";
import { toRelativeUrl } from "@okta/okta-auth-js";

import { oktaAuth } from "./config";
import LoginManager from "./LoginManager";
import Homepage from "./View/Homepage";

const MyRoutes = () => {

	const history = useNavigate();
	const originalUri = async (_oktaAuth, originalUri) => {
		history(toRelativeUrl(originalUri || "/", window.location.origin));
	};

	return (
		<Security oktaAuth={oktaAuth} restoreOriginalUri={originalUri} >

			<Routes >
				<Route path="/login/callback" element={<LoginManager />} />
				<Route path="/" exact={true} element={<Homepage />} />

			</Routes>
		</Security>
	);
};
export default MyRoutes;