import OktaAuth from "@okta/okta-auth-js";

export const oktaConfig = {
    issuer: `https://signin.kering.com/oauth2/default`,
    clientId: "0oabgcjzzvdux4pkh417",
    redirectUri: window.location.hostname === 'talentassessment.gucci.com' ? 'https://talentassessment.gucci.com/login/callback' :
        (window.location.hostname === 'hrintegration.globalbit.gucci' ? 'https://hrintegration.globalbit.gucci/talenttool/login/callback' :
            'http://localhost:3000/login/callback/'),
    postRedirectUri: window.location.hostname === 'talentassessment.gucci.com' ? 'https://talentassessment.gucci.com/login/callback' :
        (window.location.hostname === 'hrintegration.globalbit.gucci' ? 'https://hrintegration.globalbit.gucci/talenttool/login/callback' :
            'http://localhost:3000/login/callback/'),

    scopes: ["openid", "profile", "email"],
    pkce: true

};

export const oktaAuth = new OktaAuth(oktaConfig);