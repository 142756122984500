import React, { useEffect, useState } from 'react';
import Navbar from "./Navbar"
import CentralComponent from "./CentralComponent"
import { oktaAuth } from '../config';
import { useOktaAuth } from '@okta/okta-react';
import { TalentService } from '../Services/Services';

export default function Homepage(props) {


    const [user, setUser] = useState(null)
    const [component, setComponent] = useState('list')
    const { authState } = useOktaAuth();
    const [auth, setAuth] = React.useState('null')
    const ts = new TalentService()

    const loggingIn = async () => oktaAuth.signInWithRedirect({ originalUri: "/" });

    useEffect(() => {

        if (localStorage.getItem('okta-token-storage') && JSON.parse(localStorage.getItem('okta-token-storage')).accessToken && JSON.parse(localStorage.getItem('okta-token-storage')).accessToken.accessToken) {

            if (!user)
                ts.getUser().then((data) => {
                    setUser(data)
                    setAuth('auth')

                }).catch((error) => {
                    if (error.response.status == 401) {
                        if (error.response.data.code === "unauthorized") {
                            setAuth('notauth')
                        }
                        else {
                            console.log('relog1')
                            loggingIn()
                        }
                    }
                })
        } else if (!authState?.isAuthenticated) {
            if (!window.location.href.includes('code') && window.location.pathname && window.location.pathname.split('/').length > 0) {
                Promise.resolve(loggingIn())
            }
            console.log('relog2')
        }
    })
    let ref = window.location.origin
    return (
        auth === 'notauth' ? <div> <Navbar user={user} /> <div className='center_component_container'> <div className='notauth'>You are not authorized!</div></div></div> :
            (authState?.isAuthenticated && auth !== 'null' || auth === 'auth' ?
                <div>
                    <Navbar user={user} />
                    <div className='center_component_container'>
                        <nav role="navigation" class="primary-navigation" Style={"background-color: white; padding-bottom: 1px; position:fixed;z-index: 15;width: 100%;"}>
                            <ul>
                                <li>
                                    <div className={component === 'admin' ? 'selected_menu' : ''} onClick={() => setComponent('admin')}>Admin</div></li>
                                <li>
                                    <div className={component === 'development' || component === 'details' || component === 'map' ? 'selected_menu' : ''}>Talent Matrix</div>
                                    <ul class="dropdown">
                                        {/*<li><div onClick={() => setComponent('development')}>Talent Development</div></li>*/}
                                        <li><div onClick={() => setComponent('details')}>Details</div></li>
                                        <li><div onClick={() => setComponent('map')}>Map of Names</div></li>
                                    </ul>
                                </li>
                                <li><div className={component === 'list' ? 'selected_menu' : ''} onClick={() => setComponent('list')}>Talent List</div></li>
                                <li><div className={component === 'profile' ? 'selected_menu' : ''} onClick={() => setComponent('profile')}>Talent Profile</div></li>
                            </ul >
                        </nav >
                        <CentralComponent setComponent={setComponent} component={component} />
                    </div >
                </div > : (auth === 'auth' ?
                    <>  <div className="homepage"> <div className='error'> <div Style={'text-align:center'}>Error</div><br />
                        <div className='notauth'>  Please  <a href={ref} Style={'color:black'}>click here</a> to login again!</div>
                    </div></div></> : ''))
    )
}