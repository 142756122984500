import React, { useEffect, useState } from "react";
import Rightbar from "./Rightbar";
import { TalentService } from "../Services/Services"
import { BarLoader } from "react-spinners";
import SelectComponent, { InputComponent } from "./SelectComponent";
import toast, { Toaster } from 'react-hot-toast';
import Modal from 'react-modal';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
export default function TalentList(props) {

    const [talentService] = useState(new TalentService())
    const [users, setUsers] = useState([])
    const [selectedUsersId, setSelectedUsersId] = useState([])
    const [selectedUser, setSelectedUser] = useState(false)
    const [openTab, setOpenTab] = useState(false)
    const [isLoading, setLoading] = useState(true)
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [isEditingSomething, setIsEditingSomething] = useState(false)
    const [currentPage, setCurrentPage] = useState([])
    const [itemCount, setItemCount] = useState([])
    const [pageNumber, setPageNumber] = useState([])
    const [currentPageSize, setCurrentPageSize] = useState([])
    const [talentPoolOptions, setTalentPoolOptions] = useState([])
    const [devStrategyOptions, setDevStrategyOptions] = useState([])
    const [riskImpactOptions, setRiskImpactOptions] = useState([])
    const [exportingPdf, setExportingPdf] = useState(false)
    const [exportingXlsx, setExportingXlsx] = useState(false)
    const [editingUser, setEditingUser] = useState(null)
    const [counter, setCounter] = useState(null)
    const [selectedOption, setSelectedOption] = useState('')
    const [usersOptions, setUsersOptions] = useState([])
    const [height, setHeight] = useState(500)
    useEffect(() => {
        setLoading(true)
        //if (Object.keys(props.filters).length > 0) {
        let tmpObj = props.filters
        let tmpCurrentPage = props.detailFlag ? props.currentPage : 1
        tmpObj['only_filled'] = props.onlyFilled
        if (props.filters['search_text'])
            tmpObj['search_text'] = props.filters['search_text'].label
        talentService.getFilteredTalentProfileList(tmpObj, tmpCurrentPage).then((data) => {
            data.items.map((user) => {
                user.selected = false
                user.edit = false
            })
            setItemCount(data.total_count)
            props.setDetailFlag(false)
            props.setSelectedWorkdayId('')
            props.setCurrentPage(data.current_page)
            setCurrentPageSize(data.current_page_size)
            setPageNumber(data.total_page_count)
            setUsers(data.items)
            setIsEditingSomething(false)
            talentService.getUpdateOptions().then((data) => {
                setTalentPoolOptions(getSelectOptions(data.talent_pool))
                setDevStrategyOptions(getSelectOptions(data.development_strategy))
                setRiskImpactOptions(getSelectOptions(data.impact_and_risk))
                setLoading(false)

            })
            talentService.getUsersCount(props.filters).then((data) =>
                setCounter(data))
        })


    }, [props.filters, props.onlyFilled])

    useEffect(() => {
        let a = document.getElementById('talent_filters_cont')
        if (a)
            setHeight(a.clientHeight + 270)
    }, [props.openFilter])

    const openEditTab = (event, user) => {
        event.stopPropagation()
        setSelectedUser(user)
        setOpenTab(true)
    }

    const handleChangeComponent = (id) => {

        props.setDetailFlag(true)
        props.setSelectedWorkdayId(id)
        if (!isEditingSomething) {
            props.setComponent('profile')
        } else {
            setIsOpenModal(true)
        }
    }

    const handleCheckbox = (user) => {
        user.selected = !user.selected
        let tmp = props.selectedUsersId

        let index = tmp.indexOf(user.workday_id)
        if (index == -1)
            tmp.push(user.workday_id)
        else
            tmp.splice(index, 1)
        props.setSelectedUsersId(tmp)
        setUsers([...users])
    }

    const checkSelected = (id) => {
        let index = props.selectedUsersId.indexOf(id)
        if (index > -1)
            return true
        return false
    }

    const handleEditMode = (user) => {

        if (!isEditingSomething) {
            setEditingUser(user)
            setIsEditingSomething(true)
            user.edit = !user.edit
            setUsers([...users])
        } else {
            setIsOpenModal(true)
        }
    }

    const getSelectOptions = (list) => {
        let tmp = []
        list.map((e) => {
            tmp.push({ value: e, label: e })
        })
        return tmp
    }

    const save = (user) => {
        setLoading(true)
        if (editingUser.talent_pool && editingUser.development_strategy) {
            let body = {
                "talent_pool": editingUser.talent_pool,
                "development_strategy": editingUser.development_strategy,
                "potential_successors": editingUser.potential_successors,
                "potential_next_role": editingUser.potential_next_role,
                "key_strenghts": editingUser.key_strenghts,
                "key_development_areas": editingUser.key_development_areas,
                "risk_of_leaving": editingUser.risk_of_leaving,
                "impact_of_leaving": editingUser.impact_of_leaving,
            }

            talentService.updateTalentProfile(body, user.workday_id).then((data1) => {
                user.edit = false
                talentService.getFilteredTalentProfileList(props.filters, props.currentPage).then((data) => {
                    return new Promise(async (resolve, reject) => {
                        data.items.map((user) => {
                            user.selected = false
                            user.edit = false
                        })
                        setItemCount(data.total_count)
                        props.setCurrentPage(data.current_page)
                        setCurrentPageSize(data.current_page_size)
                        setPageNumber(data.total_page_count)
                        setUsers(data.items)
                        setIsEditingSomething(false)
                        resolve(data.items)
                        setLoading(false)
                    }).then(() => {
                        toast.success(data1.message)
                        setLoading(false)
                    })
                })
            }).catch((err) => {
                toast.error(err.message)
            })
        } else if (editingUser.talent_pool) {
            setLoading(false)
            toast.error("Development strategy is missing.")
        } else {
            setLoading(false)
            toast.error("Talent pool is missing.")
        }
    }

    const closeAll = () => {
        setLoading(true)
        setIsEditingSomething(false)
        setIsOpenModal(false)
        users.map((user) => {
            user.selected = false
            user.edit = false
        })
        setUsers(users)
        setEditingUser(null)
        setLoading(false)

        console.log("props.detailFlag: " + props.detailFlag)
        if (props.detailFlag) {
            props.setComponent('profile')
        }
    }

    // Invoke when user click to request another page.
    const handlePageClick = (event, currentPage) => {
        setLoading(true)
        let page = event.selected
        talentService.getFilteredTalentProfileList(props.filters, page + 1).then((data) => {
            data.items.map((user) => {
                user.selected = false
                user.edit = false
            })
            setItemCount(data.total_count)
            props.setCurrentPage(data.current_page)
            setCurrentPageSize(data.current_page_size)
            setPageNumber(data.total_page_count)
            setUsers(data.items)
            setLoading(false)
        })
    };

    const handleEditUser = (e, field) => {

        editingUser[field] = e
        setEditingUser({ ...editingUser })
    }

    const handleCloseModal = () => {
        setIsEditingSomething(false)
        setIsOpenModal(false)
    }

    var searchtimer = ''
    const searchUser = (e) => {
        clearTimeout(searchtimer);
        searchtimer = setTimeout(() => {
            if (e !== '' & e.length > 0) {
                talentService.searchUser(e).then((res) => {
                    let tmp = []
                    res.map((u) => {
                        tmp.push({
                            label: u.worker_name,
                            value: u.workday_id
                        })
                    })
                    setUsersOptions(tmp)
                })
            }
        }, 1500);
    }

    const selectOption = (e) => {
        setSelectedOption(e)
        let f = props.filters
        f['search_text'] = e
        props.setFilters({ ...f })
    }


    const customStyles = {
        control: (base, state) => ({
            ...base,
            border: state.isFocused ? 0 : 0,
            // This line disable the blue border
            boxShadow: state.isFocused ? '0px 0px 1px 1px #000  !important' : 0,
            '&:hover': {
                border: state.isFocused ? '0px 0px 1px 1px #000  !important' : 0
            }
        }),
        option: (base, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...base,
                backgroundColor: isFocused ? "#000" : isSelected ? "#000" : 'white',
                color: isFocused ? "#fff" : '#000',
                ':active': {
                    backgroundColor: '#000',
                    color: "white"
                },
            };
        }
    };

    let usersList = ''
    if (users && users.length > 0) {
        usersList = users.map((user, index) =>
            <tr className="t-row">
                <td class="t-first_column" >
                    <div className="flex t-text" Style={"place-content: center;"}>


                        <div className=" w-30p center mr-20 ml-10">

                            <input checked={user.selected ? user.selected : checkSelected(user.workday_id)} type="checkbox" className="checkbox" id={"chk_" + index} onChange={() => handleCheckbox(user)} />
                            <label className="label " htmlFor={"chk_" + index}>
                                {user.selected || checkSelected(user.workday_id) ?
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" class="bi bi-check " viewBox="0 0 16 16">
                                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                                    </svg>
                                    : ''}
                            </label>
                        </div>

                        <div className=" w-30p center" Style={'margin-top: 5px;    margin-left: 12px;'} onClick={() => handleChangeComponent(user.workday_id)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-search pointer" viewBox="0 0 16 16">
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                            </svg>
                        </div>

                        {user.edit ?
                            <React.Fragment className="pointer up w-30p center">
                                <div Style={" margin-top: 5px;"} onClick={() => save(user)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#000" class="bi bi-check-circle-fill pointer" viewBox="0 0 16 16">
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                    </svg>
                                </div>
                                <div Style={'margin-top: 5px; margin-left:5px'} onClick={() => setIsOpenModal(true)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#000" class="bi bi-x-circle pointer" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                    </svg>
                                </div>
                            </React.Fragment>
                            :
                            <div className='mr-10 up w-30p center pointer' Style={'margin-top: 5px;'} onClick={() => handleEditMode(user)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill={"#000"} class="bi bi-pencil-fill" viewBox="0 0 16 16">
                                    <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z" />
                                </svg>
                            </div>}

                    </div>
                </td>
                <td class="t-second_column" colSpan={2} Style={'position:sticky'} onClick={(e) => openEditTab(e, user)}><div className="t-text" Style={'display:grid'}><div > {user.worker_name}</div><div>({user.workday_id})</div></div></td>
                <td class="t-col ellipsis" data-label="Business title" onClick={(e) => openEditTab(e, user)}>{user.business_title ? user.business_title : '-'}</td>
                <td class="t-col " data-label="User Performance" onClick={(e) => openEditTab(e, user)}>{user.performance ? user.performance : '-'} </td>
                <td class="t-col " data-label="Talent pool">
                    {user.edit ?
                        <SelectComponent
                            options={talentPoolOptions}
                            setSelectedOption={handleEditUser}
                            field={'talent_pool'}
                            selectedOption={editingUser.talent_pool}
                            optionalLabel={"To compile"}
                        />
                        :
                        <div onClick={(e) => openEditTab(e, user)}>
                            {user.talent_pool ? user.talent_pool : '-'}
                        </div>
                    }
                </td>

                <td class="t-col" data-label="Development strategy" > {user.edit ?
                    <SelectComponent
                        options={devStrategyOptions}
                        setSelectedOption={handleEditUser}
                        field={'development_strategy'}
                        selectedOption={editingUser.development_strategy}
                        optionalLabel={"To compile"}
                    />
                    :
                    <div onClick={(e) => openEditTab(e, user)}>
                        {user.development_strategy ? user.development_strategy : '-'}
                    </div>}
                </td>

                <td class={user.edit ? "t-col" : "t-col text_cell"} data-label="Potential successors" >{user.edit ?
                    <Select
                        value={{ label: (user.potential_successors && user.potential_successors ? user.potential_successors : 'Search User') }}
                        onInputChange={(e) => searchUser(e)}
                        onChange={(e) => handleEditUser(e.label, 'potential_successors')}
                        options={usersOptions}
                        className="filter_select"
                        styles={customStyles}
                    />
                    : <div onClick={(e) => openEditTab(e, user)}>
                        {user.potential_successors ? user.potential_successors : '-'}
                    </div>}
                </td>

                <td class={user.edit ? "t-col" : "t-col text_cell"} data-label="Potential next role" >{user.edit ?
                    <InputComponent
                        value={editingUser.potential_next_role}
                        setValue={handleEditUser}
                        field={'potential_next_role'}
                    />
                    : <div onClick={(e) => openEditTab(e, user)}>
                        {user.potential_next_role ? user.potential_next_role : '-'}
                    </div>}
                </td>

                <td class={user.edit ? "t-col" : "t-col text_cell"} data-label="Key Strenghts" >
                    {user.edit ?
                        <InputComponent
                            value={editingUser.key_strenghts}
                            setValue={handleEditUser}
                            field={'key_strenghts'}
                        />
                        : <div className='ellipsis' onClick={(e) => openEditTab(e, user)}>
                            {user.key_strenghts ? user.key_strenghts : '-'}
                        </div>}
                </td>

                <td class={user.edit ? "t-col" : "t-col text_cell"} data-label="Key Development Areas" >{user.edit ?
                    <InputComponent
                        value={editingUser.key_development_areas}
                        setValue={handleEditUser}
                        field={'key_development_areas'}
                    />
                    : <div className='ellipsis' onClick={(e) => openEditTab(e, user)}>
                        {user.key_development_areas ? user.key_development_areas : '-'}
                    </div>}
                </td>

                <td class="t-col" data-label="Risk of leaving"  >{user.edit ?
                    <SelectComponent
                        options={riskImpactOptions}
                        setSelectedOption={handleEditUser}
                        selectedOption={editingUser.risk_of_leaving}
                        optionalLabel={"Select"}
                        field={'risk_of_leaving'}
                    />
                    : <div className='ellipsis' onClick={(e) => openEditTab(e, user)}>
                        {user.risk_of_leaving ?? '-'}
                    </div>
                }</td>

                <td class="t-col" data-label="Impact of leaving"  >{user.edit ?
                    <SelectComponent
                        options={riskImpactOptions}
                        setSelectedOption={handleEditUser}
                        selectedOption={editingUser.impact_of_leaving}
                        optionalLabel={"Select"}
                        field={'impact_of_leaving'}
                    />
                    :
                    <div onClick={(e) => openEditTab(e, user)}>
                        {user.impact_of_leaving ?? '-'}
                    </div>
                }
                </td>


            </tr >
        )
    } else {
        usersList = <tr></tr>
        /*
            <li class="table-row filters_line ">
                <div >No results found.</div>
            </li>*/
    }

    const customStylesModal = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    const hadleExportPdf = () => {
        if (!exportingPdf && users.length > 0) {
            setExportingPdf(true)
            let tmpObj = props.filters
            tmpObj['selected_users'] = props.selectedUsersId
            talentService.exportTalentList('pdf', tmpObj).catch((data) => {
                toast.error(data.message)
            }).finally(() => {
                setExportingPdf(false)
            })
        }
    }
    const hadleExportXlsx = () => {
        if (!exportingXlsx && users.length > 0) {
            setExportingXlsx(true)
            let tmpObj = props.filters
            tmpObj['selected_users'] = props.selectedUsersId
            talentService.exportTalentList('xlsx', tmpObj).catch((data) => {
                toast.error(data.message)
            }).finally(() => {
                setExportingXlsx(false)
            })
        }
    }
    {/*
                            {counter && counter.mapped_users && counter.total_users ? <div className="profile_text flex justify_center"><div className="bold">{counter.mapped_users}employees mapped</div>{'/' + counter.total_users}total users</div> : '-'}
                            {counter && counter.mapped_users_percentage ? <div className="profile_text">{counter.mapped_users_percentage}</div> : '-'}
                    */}
    return (
        <div className="talent_list_container center">
            <div class="talent_list_table mt-20">
                <div className="flex">
                    <div className="w-50 self_center flex">
                        <h2 className="talent_list_title">Talent List   </h2>

                        <div className="counter_card">
                            {counter && counter.mapped_users && counter.total_users ?
                                <div className="profile_text  flex">
                                    <div className="">{counter.mapped_users} Mapped employees / {counter.total_users} Total users {'(' + counter.mapped_users_percentage + ')'}
                                    </div>
                                </div> : ''}
                        </div>
                    </div>
                    <div className="w-50">
                        <div className="export_buttons_container flex mt--5">
                            <div className={!exportingPdf && users.length > 0 ? "flex export_button mr-10" : "flex export_button_disabled mr-10"} onClick={() => hadleExportPdf()}>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#000" class="bi bi-filetype-pdf" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z" />
                                    </svg>
                                </div>
                            </div>
                            <div className={!exportingXlsx && users.length > 0 ? "flex export_button" : "flex export_button_disabled"} onClick={() => hadleExportXlsx()}>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#000" class="bi bi-filetype-xlsx" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM7.86 14.841a1.13 1.13 0 0 0 .401.823q.195.162.479.252.284.091.665.091.507 0 .858-.158.355-.158.54-.44a1.17 1.17 0 0 0 .187-.656q0-.336-.135-.56a1 1 0 0 0-.375-.357 2 2 0 0 0-.565-.21l-.621-.144a1 1 0 0 1-.405-.176.37.37 0 0 1-.143-.299q0-.234.184-.384.188-.152.513-.152.214 0 .37.068a.6.6 0 0 1 .245.181.56.56 0 0 1 .12.258h.75a1.1 1.1 0 0 0-.199-.566 1.2 1.2 0 0 0-.5-.41 1.8 1.8 0 0 0-.78-.152q-.44 0-.777.15-.336.149-.527.421-.19.273-.19.639 0 .302.123.524t.351.367q.229.143.54.213l.618.144q.31.073.462.193a.39.39 0 0 1 .153.326.5.5 0 0 1-.085.29.56.56 0 0 1-.255.193q-.168.07-.413.07-.176 0-.32-.04a.8.8 0 0 1-.249-.115.58.58 0 0 1-.255-.384zm-3.726-2.909h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415H1.5l1.24-2.016-1.228-1.983h.931l.832 1.438h.036zm1.923 3.325h1.697v.674H5.266v-3.999h.791zm7.636-3.325h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415h-.861l1.24-2.016-1.228-1.983h.931l.832 1.438h.036z" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                {isLoading ?
                    <div Style={"margin-top: 5%;"}>
                        <BarLoader color="#000" />
                    </div>
                    :
                    <React.Fragment>

                        <div className="table_container " Style={'height:calc(100vh - ' + height + 'px); min-height:150px'}>


                            <table class="responsive-table mt0">
                                <thead>
                                    <tr class="table-header" Style={'width: fit-content'}>
                                        <th class="th-first_column" Style={"justify-content: center;position:sticky"}></th>
                                        <th class="th-second_column" colSpan={2} data-label="Employee" Style={'position:sticky'}>Employee</th>
                                        <th class="th-col" Style={"justify-content: center;"}>Business Title</th>
                                        <th class="th-col">Performance 2023</th>
                                        <th class="th-col">Talent Pool</th>
                                        <th class="th-col">Development Strategy</th>
                                        <th class="th-col">Potential Successor</th>
                                        <th class="th-col">Potential next role</th>
                                        <th class="th-col">Key Strenghts</th>
                                        <th class="th-col">Key Development Areas</th>
                                        <th class="th-col">Risk of leaving</th>
                                        <th class="th-col">Impact of leaving</th>
                                    </tr>
                                </thead>
                                <tbody >
                                    {usersList}
                                </tbody>
                            </table>
                        </div>
                        <ReactPaginate
                            className={"pagination"}
                            breakLabel="..."
                            nextLabel={
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#cfcfcf" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                                    </svg>
                                </div>
                            }
                            disableInitialCallback={true}
                            onPageChange={(e) => handlePageClick(e, props.currentPage)}
                            pageRangeDisplayed={3}
                            pageCount={pageNumber}
                            forcePage={(props.currentPage - 1)}
                            previousLabel={
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#cfcfcf" class="bi bi-chevron-left" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0" />
                                    </svg>
                                </div>
                            }
                            renderOnZeroPageCount={null}
                            pageClassName={"pagination_page"}
                            activeClassName={"pagination_selected_page"}
                        />
                    </React.Fragment >
                }
            </div >
            <div className='flex'>
                {openTab ?
                    <div className={openTab ? 'tab' :
                        (openTab === '' ? '' : 'closed_tab none')}>
                        <Rightbar
                            setDetailFlag={props.setDetailFlag}
                            user={selectedUser}
                            setComponent={props.setComponent}
                            setOpenTab={setOpenTab}
                            openTab={openTab}
                            handleChangeComponent={handleChangeComponent}
                        />
                    </div> : ''}


            </div>
            <Toaster
                position="bottom-right"
                toastOptions={{
                    success: {
                        style: {
                            background: 'green',
                            color: 'white'
                        },
                        iconTheme: {
                            primary: 'green',
                            secondary: 'white',
                        },
                        duration: 3000
                    },
                    error: {
                        style: {
                            background: '#000',
                            color: 'white',
                            height: '40px'
                        },
                        iconTheme: {
                            primary: '#000',
                            secondary: 'white',
                        },
                        duration: 3000
                    },
                }}
            />
            <Modal
                ariaHideApp={false}
                isOpen={isOpenModal}
                onRequestClose={() => handleCloseModal(false)}
                style={customStylesModal}
            >
                <div className="flex ">
                    <div className="modal_title bold">Are you sure?</div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#000" class="bi bi-x-circle-fill pointer" viewBox="0 0 16 16" onClick={() => setIsOpenModal(false)}>
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                    </svg>
                </div>
                If you procede changes will not be applied
                <div className="flex modal_button_container">
                    <div className="modal_button bold pointer darkRed" onClick={() => setIsOpenModal(false)}>continue editing</div>
                    <div className="modal_button book pointer black" onClick={() => closeAll()}>close</div>
                </div>
            </Modal>
        </div >
    )
}