import { Component } from "react";
import axios from 'axios';

//util function
function getAccessToken() {
    //   return '7e172b81-3ecd-4e76-bfab-3cb40823f1c9'
    return JSON.parse(localStorage.getItem('okta-token-storage')).accessToken.accessToken
}
//util function
function getApiUrl() {
    return 'https://hrintegration.globalbit.gucci/talent_tool'
}

export class TalentService extends Component {

    async getUser() {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getAccessToken();
            axios.get(getApiUrl() + '/user').then(async (response) => {
                resolve(response.data)
            }).catch(function (error) {
                console.log(error)
                if (error.response.status == 401) {
                    console.log('try')
                }
                reject(error)
            });
        })
    }
    async getTalentProfileList() {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getAccessToken();
            axios.get(getApiUrl() + '/talent-profile/v2/list?page=1&pagesize=30').then(async (response) => {
                resolve(response.data)
            }).catch(function (error) {
                console.log(error)
                if (error.response.status == 401) {
                    console.log('try')
                }
                reject(error)
            });
        })
    }
    async getFilteredTalentProfileList(filters, page) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getAccessToken();
            axios.post(getApiUrl() + '/talent-profile/v2/list/filter?page=' + page + '&pagesize=50', filters).then(async (response) => {
                resolve(response.data)
            }).catch(function (error) {
                console.log(error)
                if (error.response.status == 401) {
                    console.log('try')
                }
                reject(error)
            });
        })
    }
    async getUsersCount(filters) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getAccessToken();
            axios.post(getApiUrl() + '/talent-profile/list/mapped-count', filters).then(async (response) => {
                resolve(response.data)
            }).catch(function (error) {
                console.log(error)
                if (error.response.status == 401) {
                    console.log('try')
                }
                reject(error)
            });
        })
    }

    async getListDetail(id) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getAccessToken();
            axios.get(getApiUrl() + '/talent-profile/list/' + id).then(async (response) => {
                resolve(response.data)
            }).catch(function (error) {
                console.log(error)
                if (error.response.status == 401) {
                    console.log('try')
                }
                reject(error)
            });
        })
    }

    async getTalentProfile(id) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getAccessToken();
            axios.get(getApiUrl() + '/talent-profile/v2/' + id).then(async (response) => {
                resolve(response.data)
            }).catch(function (error) {
                console.log(error)
                if (error.response.status == 401) {
                    console.log('try')
                }
                reject(error)
            });
        })
    }

    async getDevelopmentMatrix(id) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getAccessToken();
            axios.get(getApiUrl() + '/talent-matrix/v1/count').then(async (response) => {
                resolve(response.data)
            }).catch(function (error) {
                console.log(error)
                if (error.response.status == 401) {
                    console.log('try')
                }
                reject(error)
            });
        })
    }

    async getFilteredMatrix(filters) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getAccessToken();
            axios.post(getApiUrl() + '/talent-matrix/filter', filters).then(async (response) => {
                resolve(response.data)
            }).catch(function (error) {
                console.log(error)
                if (error.response.status == 401) {
                    console.log('try')
                }
                reject(error)
            });
        })
    }


    async getNameMatrix() {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getAccessToken();
            axios.get(getApiUrl() + '/talent-matrix/name').then(async (response) => {
                resolve(response.data)
            }).catch(function (error) {
                console.log(error)
                if (error.response.status == 401) {
                    console.log('try')
                }
                reject(error)
            });
        })
    }

    async getFilteredNameMatrix(filters) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getAccessToken();
            axios.post(getApiUrl() + '/talent-matrix/v2/name/filter', filters).then(async (response) => {
                resolve(response.data)
            }).catch(function (error) {
                console.log(error)
                if (error.response.status == 401) {
                    console.log('try')
                }
                reject(error)
            });
        })
    }


    async exportTalentMatrix(file_type, filters) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getAccessToken();
            axios.post(getApiUrl() + '/talent-matrix/name/export/' + file_type, filters, { responseType: 'blob' }).then((res) => {

                const type = file_type === 'xlsx' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'application/pdf'
                const blob = new Blob([res.data], { type: type })
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = 'Map of Names.' + file_type
                link.click()
                resolve()

            }).catch((error) => {
                reject(error)
            })

        })
    }



    async getTalentPoolOptions() {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getAccessToken();
            axios.get(getApiUrl() + '/talent-profile/talent-pool').then(async (response) => {
                resolve(response.data)
            }).catch(function (error) {
                console.log(error)
                if (error.response.status == 401) {
                    console.log('try')
                }
                reject(error)
            });
        })
    }

    async getDevStrategyOptions() {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getAccessToken();
            axios.get(getApiUrl() + '/talent-profile/development-strategy').then(async (response) => {
                resolve(response.data)
            }).catch(function (error) {
                console.log(error)
                if (error.response.status == 401) {
                    console.log('try')
                }
                reject(error)
            });
        })
    }

    async getUpdateOptions() {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getAccessToken();
            axios.get(getApiUrl() + '/talent-profile/update-options').then(async (response) => {
                resolve(response.data)
            }).catch(function (error) {
                console.log(error)
                if (error.response.status == 401) {
                    console.log('try')
                }
                reject(error)
            });
        })
    }

    async updateTalentProfile(body, id) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getAccessToken();
            axios.post(getApiUrl() + '/talent-profile/' + id + '/update', body).then(async (response) => {
                resolve(response.data)
            }).catch(function (error) {
                console.log(error)
                if (error.response.status == 401) {
                    console.log('try')
                }
                reject(error)
            });
        })
    }

    async searchUser(text) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getAccessToken();
            axios.get(getApiUrl() + '/talent-profile/search?search_text=' + text).then(async (response) => {
                resolve(response.data)
            }).catch(function (error) {
                console.log(error)
                if (error.response.status == 401) {
                    console.log('try')
                }
                reject(error)
            });
        })
    }
    async getLastImports() {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getAccessToken();
            axios.get(getApiUrl() + '/import/last-imports').then(async (response) => {
                resolve(response.data)
            }).catch(function (error) {
                console.log(error)
                if (error.response.status == 401) {
                    console.log('try')
                }
                reject(error)
            });
        })
    }
    async getImportTypes() {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getAccessToken();
            axios.get(getApiUrl() + '/import/file-type').then(async (response) => {
                resolve(response.data)
            }).catch(function (error) {
                console.log(error)
                if (error.response.status == 401) {
                    console.log('try')
                }
                reject(error)
            });
        })
    }
    async uploadFile(file, file_type) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getAccessToken();
            axios.defaults.headers.common['Content-Type'] = "multipart/form-data"
            axios.post(getApiUrl() + '/import/' + file_type, file).then(async (response) => {
                console.log(response)
                resolve(response.data)
            }).catch(function (error) {
                console.log(error)
                if (error.response.status == 401) {
                    console.log('try')
                }
                reject(error)
            });
        })
    }
    async getFilters(toFilter) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getAccessToken();
            axios.post(getApiUrl() + '/talent-profile/filters', toFilter).then(async (response) => {
                resolve(response.data)
            }).catch(function (error) {
                console.log(error)
                if (error.response.status == 401) {
                    console.log('try')
                }
                reject(error)
            });
        })
    }

    /* EXPORT TALENT LIST */
    async exportTalentProfile(workday_id, name) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getAccessToken();
            axios.get(getApiUrl() + '/talent-profile/' + workday_id + '/export/pdf', { responseType: 'blob' }).then((res) => {

                const type = 'application/pdf'
                const blob = new Blob([res.data], { type: type })
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = name + '.pdf'
                link.click()
                resolve()

            }).catch((error) => {
                reject(error)
            })

        })
    }
    async exportTalentList(file_type, filters) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getAccessToken();
            axios.post(getApiUrl() + '/talent-profile/export/' + file_type, filters, { responseType: 'blob' }).then((res) => {

                const type = file_type === 'xlsx' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'application/pdf'
                const blob = new Blob([res.data], { type: type })
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = 'Talent_list.' + file_type
                link.click()
                resolve()

            }).catch((error) => {
                reject(error)
            })

        })
    }


}