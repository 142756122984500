import React, { useEffect, useState } from "react";
import Select from 'react-select';
import Modal from 'react-modal';
import { TalentService } from "../Services/Services"
import toast, { Toaster } from 'react-hot-toast';
import { BarLoader } from "react-spinners";

import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';


export default function Rightbar(props) {

    const [talentService] = useState(new TalentService())
    const [listDetail, setListDetail] = useState([])
    const [talentPoolOptions, setTalentPoolOptions] = useState([])
    const [devStrategyOptions, setDevStrategyOptions] = useState([])
    const [riskImpactOptions, setRiskImpactOptions] = useState([])
    const [selectedTalentPool, setSelectedTalentPool] = useState([])
    const [selectedDevStrategy, setSelectedDevStrategy] = useState([])
    const [setRiskOfLeaving, setSelectedRiskOfLeaving] = useState([])
    const [setImpactOfLeaving, setSelectedImpactOfLeaving] = useState([])
    const [potentialNextRole, setPotentialNextRole] = useState([])
    const [potentialSuccessors, setPotentialSuccessors] = useState([])
    const [additionalNotes, setAdditionalNotes] = useState([])
    const [editMode, setEditMode] = React.useState(false)
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [isLoading, setLoading] = useState(true)

    useEffect(() => {

        let talentPool = []
        let devStrategy = []
        let riskImpactOptions = []
        if (!editMode) {
            setLoading(true)
            talentService.getListDetail(props.user.workday_id).then((data) => {
                setListDetail(data)
                setSelectedTalentPool(data.talent_pool)
                setSelectedDevStrategy(data.development_strategy)
                setSelectedRiskOfLeaving(data.risk_of_leaving)
                setSelectedImpactOfLeaving(data.impact_of_leaving)
                setPotentialNextRole(data.potential_next_role ?? '-')
                setPotentialSuccessors(data.potential_successors ?? '-')
                setAdditionalNotes(data.additional_notes ?? '-')
                setLoading(false)
                //talentService.getUpdateOptions().then((data) => {
                //    talentPool = getSelectOptions(data.talent_pool)
                //    devStrategy = getSelectOptions(data.development_strategy)
                //    riskImpactOptions = getSelectOptions(data.impact_and_risk)
                //    setTalentPoolOptions(talentPool)
                //    setDevStrategyOptions(devStrategy)
                //    setRiskImpactOptions(riskImpactOptions)
                //    setLoading(false)
                //})
            })
        }
    }, [props.user.workday_id])

    useEffect(() => {
        addListener()

        return () => {
            removeListener()
        }

    }, [editMode])

    const check = (event) => {
        var right_tab = document.querySelector('.right_tab');
        if (right_tab) {
            if (!right_tab.contains(event.target))
                if (!event.target.matches('.cell_height') && !event.target.matches('.table-row')) {
                    handleClose()
                }
        }
    }

    const addListener = () => {
        window.addEventListener("click", check, false);
    }

    const removeListener = () => {
        window.removeEventListener("click", check, false);
    }

    const handleEditMode = (event) => {
        event.stopPropagation()
        if (!editMode) {
            console.log("metto editmode a true")
            setEditMode(true)
        } else {
            console.log("metto editmode a false")
            setEditMode(false)
        }

    }

    const getSelectOptions = (list) => {
        let tmp = []
        list.map((e) => {
            tmp.push({ value: e, label: e })
        })
        return tmp
    }

    const customStyles = {
        control: (base, state) => ({
            ...base,
            border: state.isFocused ? 0 : 0,
            // This line disable the blue border
            boxShadow: state.isFocused ? '0px 0px 1px 1px #000  !important' : 0,
            '&:hover': {
                border: state.isFocused ? '0px 0px 1px 1px #000  !important' : 0
            }
        }),
        option: (base, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...base,
                backgroundColor: isFocused ? "#000" : isSelected ? "#000" : 'white',
                ':active': {
                    backgroundColor: '#000',
                    color: "white"
                }
            };
        }
    };

    const handleChangeTalentPool = (e) => {
        setSelectedTalentPool(e.value)
    }

    const handleChangeDevStrategy = (e) => {
        setSelectedDevStrategy(e.value)
    }

    const handleChangeRisk = (e) => {
        setSelectedRiskOfLeaving(e.value)
    }

    const handleChangeImpact = (e) => {
        setSelectedImpactOfLeaving(e.value)
    }

    const handleChangeNextRole = (e) => {
        setPotentialNextRole(e.target.value)
    }

    const handleChangeSuccessors = (e) => {
        setPotentialSuccessors(e.target.value)
    }

    const handleChangeNotes = (e) => {
        setAdditionalNotes(e.target.value)
    }

    const saveInfos = () => {
        console.log('save')
        if (selectedTalentPool && selectedDevStrategy) {
            let body = {
                "talent_pool": selectedTalentPool,
                "development_strategy": selectedDevStrategy,
                "risk_of_leaving": setRiskOfLeaving,
                "impact_of_leaving": setImpactOfLeaving,
                "potential_next_role": potentialNextRole === '-' ? '' : potentialNextRole,
                "potential_successors": potentialSuccessors === '-' ? '' : potentialSuccessors,
                "additional_notes": additionalNotes === '-' ? '' : additionalNotes
            }
            talentService.updateTalentProfile(body, props.user.workday_id).then((data) => {
                toast.success(data.message)
            })
            setEditMode(false)
            setIsOpenModal(false)
        } else if (selectedTalentPool) {
            toast.error("Talent pool is missing.")
        } else {
            toast.error("Development strategy is missing.")
        }
    }

    const handleClose = () => {
        toast.dismiss()
        if (editMode) {
            setIsOpenModal(true)
        } else {
            props.setOpenTab(false)
        }
    }

    const closeModal = (event) => {
        event.stopPropagation()
        setIsOpenModal(false)
    }

    const closeAll = () => {
        toast.dismiss()
        setEditMode(false)
        props.setOpenTab(false)
        setIsOpenModal(false)
    }

    const customStylesModal = {
        content: {
            "z-index": 8,
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };


    return (
        <div className="flex">
            <Modal
                ariaHideApp={false}
                isOpen={isOpenModal}
                onRequestClose={(e) => closeModal(e)}
                style={customStylesModal}
            >
                <div className="flex ">
                    <div className="modal_title bold">Are you sure?</div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#b0020285" class="bi bi-x-circle-fill pointer" viewBox="0 0 16 16" onClick={(e) => closeModal(e)}>
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                    </svg>
                </div>
                If you procede changes will not be applied
                <div className="flex modal_button_container">
                    <div className="modal_button bold pointer darkRed" onClick={(e) => closeModal(e)}>continue editing</div>
                    <div className="modal_button book pointer defaultColor" onClick={() => closeAll()}>close</div>
                </div>
            </Modal>

            <div className="right_tab">
                {/*
                <div className="close_right_tab">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#000" class="bi bi-x-circle-fill pointer" viewBox="0 0 16 16" onClick={() => handleClose()}>
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                    </svg>
                </div>
                */}
                {isLoading ?
                    <div Style={" text-align: -webkit-center; margin-top: 50%;"}>
                        <BarLoader color="#000" />
                    </div>
                    :
                    <React.Fragment>
                        <div className="center">
                            <div className="profile_picture_right_tab mt-30"></div>
                        </div>
                        <div className="right_tab_title mt-10">{listDetail.worker_name}</div>
                        <div className="right_tab_container  mt-30">
                            <div className="right_tab_info_container">
                                <div className="right_tab_info_row">
                                    <div className="bold right_tab_section_name">Worker's info</div>
                                    <div className="flex">
                                        <div className="bold right_tab_category_name mr-5">Function:</div>
                                        <div className="book right_tab_value_name">{listDetail.business_title ?? '-'}</div>
                                    </div>
                                    <div className="flex">
                                        <div className="bold right_tab_category_name mr-5">Region:</div>
                                        <div className="book right_tab_value_name">{listDetail.region ?? '-'}</div>
                                    </div>
                                    <div className="flex">
                                        <div className="bold right_tab_category_name mr-5">Country:</div>
                                        <div className="book right_tab_value_name">{listDetail.country ?? '-'} </div>
                                    </div>
                                    <div className="flex">
                                        <div className="bold right_tab_category_name mr-5">Manager:</div>
                                        <div className="book right_tab_value_name">{listDetail.manager ?? '-'} </div>
                                    </div>
                                    <div className="flex">
                                        <div className="bold right_tab_category_name mr-5">Span of control:</div>
                                        <div className="book right_tab_value_name">{listDetail.span_of_control ?? '-'} </div>
                                    </div>
                                    <div className="flex">
                                        <div className="bold right_tab_category_name mr-5">Layer:</div>
                                        <div className="book right_tab_value_name">{listDetail.layer ?? '-'} </div>
                                    </div>
                                    <div className="flex">
                                        <div className="bold right_tab_category_name mr-5">Time in position:</div>
                                        <div className="book right_tab_value_name">{listDetail.time_in_position ?? '-'} </div>
                                    </div>
                                    <div className="flex">
                                        <div className="bold right_tab_category_name mr-5">Time in kering:</div>
                                        <div className="book right_tab_value_name">{listDetail.time_in_kering ?? '-'} </div>
                                    </div>
                                </div>
                            </div>
                            <div className="right_tab_timeline_container">
                                <VerticalTimeline
                                    lineColor={'#000'}
                                //layout={'1-column-right'}
                                >
                                    {listDetail.business_title_history.map((t) =>
                                        <VerticalTimelineElement
                                            className="vertical-timeline-element--work"
                                            contentStyle={{ color: '#000' }}
                                            contentArrowStyle={{ display: 'none' }}
                                            date={t.year}
                                            iconStyle={{ background: '#000', color: '#fff' }}
                                            position={'right'}
                                        //icon={<WorkIcon />}
                                        >
                                            <div className="vertical-timeline-element-title bold">{t.layer}</div>
                                        </VerticalTimelineElement>
                                    )}
                                </VerticalTimeline>
                            </div>
                            <div className="right_tab_info_container">
                                <div className="right_tab_info_row">
                                    <div className="flex">
                                        <div className="bold right_tab_category_name mr-5">Impact of leaving:</div>
                                        <div className="book right_tab_value_name">
                                            {listDetail.impact_of_leaving == 'LOW' ?
                                                <React.Fragment>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="#000" class="bi bi-circle-fill" viewBox="0 0 16 16">
                                                        <circle cx="8" cy="8" r="8" />
                                                    </svg><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-circle" viewBox="0 0 16 16">
                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                    </svg>
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="#000" class="bi bi-circle-fill" viewBox="0 0 16 16">
                                                        <circle cx="8" cy="8" r="8" />
                                                    </svg><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="#000" class="bi bi-circle-fill" viewBox="0 0 16 16">
                                                        <circle cx="8" cy="8" r="8" />
                                                    </svg>
                                                </React.Fragment>
                                            }</div>
                                    </div>
                                    <div className="flex">
                                        <div className="bold right_tab_category_name mr-5">Risk of leaving:</div>
                                        <div className="book right_tab_value_name">{listDetail.risk_of_leaving == 'LOW' ?
                                            <React.Fragment>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="#000" class="bi bi-circle-fill" viewBox="0 0 16 16">
                                                    <circle cx="8" cy="8" r="8" />
                                                </svg><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-circle" viewBox="0 0 16 16">
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                </svg>
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="#000" class="bi bi-circle-fill" viewBox="0 0 16 16">
                                                    <circle cx="8" cy="8" r="8" />
                                                </svg><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="#000" class="bi bi-circle-fill" viewBox="0 0 16 16">
                                                    <circle cx="8" cy="8" r="8" />
                                                </svg>
                                            </React.Fragment>
                                        } </div>
                                    </div>
                                    <div className="flex mt-10">
                                        <div className="bold right_tab_category_name mr-5">Talent Assestment 2022:</div>
                                        <div className="book ">{listDetail.talent_assestment ?? '-'} </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="right_tab_button_container">

                            {editMode ?
                                <React.Fragment>
                                    <div className="right_tab_button darkRed" onClick={() => saveInfos()}>Save</div>
                                    <div className="right_tab_button defaultColor" onClick={(e) => handleEditMode(e)}>Cancel</div>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    {/*
                                    <div className="right_tab_button darkRed" onClick={(e) => handleEditMode(e)}>
                                        <div className="flex" Style={"align-items: center; justify-content: center;"}>
                                            Edit
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill ml-10" viewBox="0 0 16 16">
                                                <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z" />
                                            </svg>
                                        </div>
                                    </div>
                                    */}
                                    <div className="right_tab_button darkRed" onClick={() => props.handleChangeComponent(props.user.workday_id)}>View more</div>
                                </React.Fragment>}

                        </div>
                    </React.Fragment>}
            </div>
            <Toaster
                toastOptions={{
                    success: {
                        style: {
                            background: 'green',
                            color: 'white'
                        },
                        iconTheme: {
                            primary: 'green',
                            secondary: 'white',
                        },
                        duration: 3000
                    },
                    error: {
                        style: {
                            background: '#000',
                            color: 'white'
                        },
                        iconTheme: {
                            primary: '#000',
                            secondary: 'white',
                        },
                        duration: 3000
                    },
                }}
            />
        </div >
    )
}