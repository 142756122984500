import React, { useEffect, useState } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { TalentService } from '../Services/Services';
import { MultiselectComponent } from './SelectComponent';
import Select from 'react-select';
export default function Filters(props) {

    const [talentService] = useState(new TalentService)
    const [orgMaf, setOrgMaf] = useState([])
    const [orgMif, setOrgMif] = useState([])
    const [compensationGrade, setCompensationGrade] = useState([])
    const [selectedMaf, setSelectedMaf] = useState([])
    const [selectedMif, setSelectedMif] = useState([])
    const [selectedCompensation, setSelectedCompensation] = useState([])
    const [loading, setLoading] = useState(false)

    const [clusteOptions, setClusteOptions] = useState([])
    const [orgRegionOptions, setOrgRegionOptions] = useState([])
    const [countryOptions, setCountryOptions] = useState([])
    const [jobProfileOptions, setJobProfileOptions] = useState([])
    const [wManagerOptions, setWManagerOptions] = useState([])
    const [devStrategyOptions, setDevStrategyOptions] = useState([])
    const [talentPoolOptions, setTalentPoolOptions] = useState([])
    const [isActiveOptions, setIsActiveOptions] = useState([])
    const [isActive, setIsActive] = useState(props.filters['is_active'] ? { value: props.filters['is_active'], label: props.filters['is_active'] } : '')

    // search
    const [selectedOption, setSelectedOption] = useState(props.filters['employee'] ?? '')
    const [usersOptions, setUsersOptions] = useState([])

    // multiselect refs
    const mafRef = React.createRef();
    const mifRef = React.createRef();
    const compensationRef = React.createRef();
    const clusterRef = React.createRef();
    const regionRef = React.createRef();
    const countryRef = React.createRef();
    const jobRef = React.createRef();
    const managerRef = React.createRef();
    const talentPoolRef = React.createRef();
    const devStrategyRef = React.createRef();


    useEffect(() => {
        console.log(props.filters)
        let e = selectedMaf
        let tmp = []
        if (e && e.length > 0)
            e.map((s) => {
                tmp.push(s.name)
            })
        let toFilter = {
            "org_maf": tmp
        }

        talentService.getFilters(toFilter).then((res) => {
            let tm = selectedMif
            if (res.org_mif && res.org_mif.length == 0) {
                tm = []
                setSelectedMif([])
            }
            res.org_mif.map(() => {
                tm.map((s, i) => {
                    if (!res.org_mif.includes(s.name)) {
                        tm.splice(i, 1)
                    }
                })
                setSelectedMif(tm)
            })
            setSelectOptions(res.org_maf, setOrgMaf)
            setSelectOptions(res.org_mif, setOrgMif)
            setSelectOptions(res.compensation_grade, setCompensationGrade)
            setSelectOptions(res.cluster, setClusteOptions)
            setSelectOptions(res.org_region, setOrgRegionOptions)
            setSelectOptions(res.country, setCountryOptions)
            setSelectOptions(res.job_profile, setJobProfileOptions)
            setSelectOptions(res.manager, setWManagerOptions)
            setSelectOptions(res.talent_pool, setTalentPoolOptions)
            setSelectOptions(res.development_strategy, setDevStrategyOptions)
            setSelectOptions(res.development_strategy, setDevStrategyOptions)
            getSelectOptions(res.is_active, setIsActiveOptions)
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
    }, [props.filters])

    const multiselectStyle = {
        chips: {
            background: '#000',
            'font-size': '12px',
            'border-radius': '15px',
            'line-height': '14px',
            'margin-bottom': '1px'
        },
        multiselectContainer: {
            color: '#000',
        },
        searchBox: {
            border: 'none',
            'border-bottom': '1px solid #000',
            'border-radius': '0px',
            'margin-top': '5px',
            cursor: 'pointer'
        }
    }

    const selectMaf = (e) => {
        setLoading(true)
        setSelectedMaf(e)
        let ts = new TalentService()
        let tmp = []
        if (e && e.length > 0)
            e.map((s) => {
                tmp.push(s.name)
            })
        let toFilter = {
            "org_maf": tmp
        }

        ts.getFilters(toFilter).then((res) => {
            let tm = selectedMif
            if (res.org_mif && res.org_mif.length == 0) {
                tm = []
                setSelectedMif([])
            }
            res.org_mif.map((mif) => {
                tm.map((s, i) => {
                    if (!res.org_mif.includes(s.name)) {
                        tm.splice(i, 1)
                    }
                })
                setSelectedMif(tm)
            })
            //setOrgMaf(tmp_maf)
            //setOrgMif(tmp_mif)
            //setCompensationGrade(tmp_cg)
            filter(e, tm)
            setSelectOptions(res.org_maf, setOrgMaf)
            setSelectOptions(res.org_mif, setOrgMif)
            setSelectOptions(res.compensation_grade, setCompensationGrade)
            setSelectOptions(res.cluster, setClusteOptions)
            setSelectOptions(res.org_region, setOrgRegionOptions)
            setSelectOptions(res.country, setCountryOptions)
            setSelectOptions(res.job_profile, setJobProfileOptions)
            setSelectOptions(res.manager, setWManagerOptions)
            setSelectOptions(res.talent_pool, setTalentPoolOptions)
            setSelectOptions(res.development_strategy, setDevStrategyOptions)
            setSelectOptions(res.development_strategy, setDevStrategyOptions)
            getSelectOptions(res.is_active, setIsActiveOptions)
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
    }

    const setSelectOptions = (array, setFunction) => {
        let tmp = []
        array.map((e) => {
            tmp.push({
                name: e
            })
        })
        setFunction(tmp)
    }

    const getSelectOptions = (list, setFunction) => {
        let tmp = []
        list.map((e) => {
            tmp.push({ value: e, label: e })
        })
        setFunction(tmp)
    }

    const selectMif = (e) => {
        setLoading(true)
        setSelectedMif(e)
        let ts = new TalentService()
        let tmp = []
        if (selectedMaf && selectedMaf.length > 0)
            selectedMaf.map((s) => {
                tmp.push(s.name)
            })
        let toFilter = {
            "org_maf": tmp
        }
        ts.getFilters(toFilter).then((res) => {
            filter(selectedMaf, e)
            setSelectOptions(res.org_maf, setOrgMaf)
            setSelectOptions(res.org_mif, setOrgMif)
            setSelectOptions(res.compensation_grade, setCompensationGrade)
            setLoading(false)

        }).catch(() => {
            setLoading(false)
        })
    }
    const selectCompensation = (e) => {
        setSelectedCompensation(e)
        filter(selectedMaf, selectedMif, e)
    }

    const filter = (maf, mif) => {
        let filterObject = props.filters
        filterObject['org_maf'] = maf.map(({ name }) => name)
        filterObject['org_mif'] = mif.map(({ name }) => name)
        props.setFilters({ ...filterObject })
    }

    const handleChangeCheckbox = () => {
        props.setOnlyFilled(!props.onlyFilled)
    }

    const customStyles = {
        control: (base, state) => ({
            ...base,
            border: state.isFocused ? 0 : 0,
            // This line disable the blue border
            boxShadow: state.isFocused ? '0px 0px 1px 1px #000  !important' : 0,
            '&:hover': {
                border: state.isFocused ? '0px 0px 1px 1px #000  !important' : 0
            }
        }),
        option: (base, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...base,
                backgroundColor: isFocused ? "#000" : isSelected ? "#000" : 'white',
                color: isFocused ? "#fff" : '#000',
                ':active': {
                    backgroundColor: '#000',
                    color: "white"
                },
            };
        }
    };

    const setField = (list, field) => {
        let f = props.filters
        f[field] = list.map(({ name }) => name)
        if (field === 'org_maf') {
            setSelectedMaf(list)
            console.log(list)
            if (list.length == 0) {
                f['org_mif'] = []
                setSelectedMif([])
            }
        }
        props.setFilters({ ...f })
    }


    var searchtimer = ''
    const searchUser = (e) => {
        clearTimeout(searchtimer);
        searchtimer = setTimeout(() => {
            if (e !== '' & e.length > 0) {
                talentService.searchUser(e).then((res) => {
                    let tmp = []
                    res.map((u) => {
                        tmp.push({
                            label: u.worker_name,
                            value: u.workday_id
                        })
                    })
                    setUsersOptions(tmp)
                })
            }
        }, 1500);
    }
    const selectOption = (e) => {
        setSelectedOption(e)
        let f = props.filters
        f['search_text'] = e
        props.setFilters({ ...f })
    }

    const clearAll = () => {
        let tmp = {}
        props.setFilters({ ...tmp })
        mafRef.current.resetSelectedValues();
        setSelectedMaf([])
        if (selectedMif && selectedMif.length > 0)
            mifRef.current.resetSelectedValues();
        compensationRef.current.resetSelectedValues();
        clusterRef.current.resetSelectedValues();
        regionRef.current.resetSelectedValues();
        countryRef.current.resetSelectedValues();
        jobRef.current.resetSelectedValues();
        managerRef.current.resetSelectedValues();
        talentPoolRef.current.resetSelectedValues();
        devStrategyRef.current.resetSelectedValues();
        setIsActive('')
        setSelectedOption('')
        props.setOnlyFilled(false)
    }

    const handleActive = (e) => {
        setIsActive(e)
        let f = props.filters
        f['is_active'] = e.value
        props.setFilters({ ...f })
    }

    return (
        <div className="grid w-100 mt-50" id='talent_filters_cont'>
            <div className='flex' Style={"align-items: center"}>
                <h2>Filters </h2>
                {props.openFilter ?
                    <div className='mt-5 pointer' onClick={() => props.setOpenFilter(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z" />
                        </svg>
                    </div>
                    :
                    <div className='mt-5 pointer' onClick={() => props.setOpenFilter(true)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
                        </svg>
                    </div>}
            </div>
            {props.openFilter ?
                <div className='flex ml-40  wrap'>
                    <div className='filter_block'>
                        <label className='bold'>Employee</label>
                        <Select
                            value={{ label: (selectedOption && selectedOption.label ? selectedOption.label : 'Search User') }}
                            onInputChange={(e) => searchUser(e)}
                            onChange={(e) => selectOption(e)}
                            options={usersOptions}
                            className="filter_select"
                            styles={customStyles}
                        />
                    </div>
                    <div className='filter_block '>
                        <label className='bold'>Org Maf:</label>
                        <Multiselect
                            options={orgMaf} // Options to display in the dropdown
                            selectedValues={selectedMaf} // Preselected value to persist in dropdown
                            onSelect={(e) => setField(e, 'org_maf')} // Function will trigger on select event
                            onRemove={(e) => setField(e, 'org_maf')} // Function will trigger on remove event
                            displayValue="name" // Property name to display in the dropdown options
                            showCheckbox={true}
                            avoidHighlightFirstOption={true}
                            style={multiselectStyle}
                            ref={mafRef} />
                    </div>
                    {orgMif && orgMif.length > 0 ?
                        <div className='filter_block'>
                            <label className='bold'>Org Mif:</label>
                            {!loading ? <Multiselect
                                options={orgMif} // Options to display in the dropdown
                                selectedValues={!loading ? selectedMif : ''} // Preselected value to persist in dropdown
                                onSelect={(e) => setField(e, 'org_mif')} // Function will trigger on select event
                                onRemove={(e) => setField(e, 'org_mif')} // Function will trigger on remove event
                                displayValue="name" // Property name to display in the dropdown options
                                showCheckbox={true}
                                avoidHighlightFirstOption={true}
                                style={multiselectStyle}
                                ref={mifRef}
                            /> : ''}

                        </div> : ''}

                    <div className='filter_block'>
                        <label className='bold'>Compensation Grade:</label>
                        <MultiselectComponent
                            id={"compensation_grade"}
                            options={compensationGrade} // Options to display in the dropdown
                            field={'compensation_grade'}
                            setField={setField}
                            multiselectRef={compensationRef}
                            prevSelected={props.filters['compensation_grade'] ?? ''}
                        />

                    </div>
                    <div className='filter_block'>
                        <label className='bold'>Cluster:</label>
                        <MultiselectComponent
                            id={"cluster"}
                            options={clusteOptions} // Options to display in the dropdown
                            setField={setField}
                            field={'cluster'}
                            multiselectRef={clusterRef}
                            prevSelected={props.filters['cluster'] ?? ''}
                        />

                    </div>
                    <div className='filter_block'>
                        <label className='bold'>Org Region:</label>
                        <MultiselectComponent
                            options={orgRegionOptions} // Options to display in the dropdown
                            setField={setField}
                            field={'org_region'}
                            multiselectRef={regionRef}
                            prevSelected={props.filters['org_region'] ?? ''}
                        />
                    </div>

                    <div className='filter_block'>
                        <label className='bold'>Country:</label>
                        <MultiselectComponent
                            options={countryOptions} // Options to display in the dropdown
                            setField={setField}
                            field={'country'}
                            multiselectRef={countryRef}
                            prevSelected={props.filters['country'] ?? ''}
                        />
                    </div>
                    <div className='filter_block'>
                        <label className='bold'>Job profile:</label>
                        <MultiselectComponent
                            options={jobProfileOptions} // Options to display in the dropdown
                            setField={setField}
                            field={'job_profile'}
                            multiselectRef={jobRef}
                            prevSelected={props.filters['job_profile'] ?? ''}
                        />
                    </div>
                    <div className='filter_block'>
                        <label className='bold'>Worker's Manager:</label>
                        <MultiselectComponent
                            options={wManagerOptions} // Options to display in the dropdown
                            setField={setField}
                            field={'manager'}
                            multiselectRef={managerRef}
                            prevSelected={props.filters['manager'] ?? ''}
                        />
                    </div>
                    <div className='filter_block'>
                        <label className='bold'>Talent pool:</label>
                        <MultiselectComponent
                            options={talentPoolOptions} // Options to display in the dropdown
                            setField={setField}
                            field={'talent_pool'}
                            multiselectRef={talentPoolRef}
                            prevSelected={props.filters['talent_pool'] ?? ''}
                        />
                    </div>
                    <div className='filter_block'>
                        <label className='bold'>Development strategy:</label>
                        <MultiselectComponent
                            options={devStrategyOptions} // Options to display in the dropdown
                            setField={setField}
                            field={'development_strategy'}
                            multiselectRef={devStrategyRef}
                            prevSelected={props.filters['development_strategy'] ?? ''}
                        />
                    </div>
                    <div className='filter_block'>
                        <label className='bold'>Is active:</label>
                        <Select
                            onChange={handleActive}
                            value={{
                                label:
                                    (isActive.label ? isActive.label : 'Select')
                            }}
                            className="filter_select"
                            options={isActiveOptions}
                            styles={customStyles}

                        />
                    </div>
                    <div className='flex mt-20 filter_block' Style={"align-items: center; align-self: center;"}>
                        <div className='flex mr-20'>
                            <input checked={props.onlyFilled} type="checkbox" className="checkbox" id={"chk"} onChange={() => handleChangeCheckbox()} />
                            <label className="label" htmlFor={"chk"}>
                                {props.onlyFilled ?
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" class="bi bi-check" viewBox="0 0 16 16">
                                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                                    </svg>
                                    : ''}
                            </label>
                            <div className='bold' Style={"margin-left: 5px; "} >
                                Only Filled
                            </div>
                        </div>
                        <div className="export_button flex" onClick={() => clearAll()} Style={"height:30px; min-height: 30px; margin-left:10px"}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#000" class="bi bi-x" viewBox="0 0 16 16">
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                            </svg>
                            <div>clear</div>
                        </div>
                    </div>
                </div> : ''}
        </div >
    )
}