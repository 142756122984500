import React, { useEffect, useState } from "react"
import { TalentService } from "../Services/Services"
import Modal from 'react-modal';

export default function MapOfNames(props) {
    const ts = new TalentService()
    const [matrixInfo, setMatrixInfo] = useState('')
    const [openModal, setOpenModal] = useState(false)
    const [fistParameterModalTitle, setFistParameterModalTitle] = useState('')
    const [secondParameterModalTitle, setSecondParameterModalTitle] = useState('')
    const [users, setUsers] = useState([])
    const [exportingPdf, setExportingPdf] = useState(false)
    const [exportingXlsx, setExportingXlsx] = useState(false)

    useEffect(() => {

        let tmpObj = props.filters
        tmpObj['only_filled'] = props.onlyFilled
        if (props.filters['employee'])
            tmpObj['employee'] = props.filters['employee'].value
        ts.getFilteredNameMatrix(props.filters).then((data) => {
            setMatrixInfo(data)
        })

    }, [props.filters])


    let first_subcategories = ''
    let second_subcategories = ''

    if (matrixInfo) {

        first_subcategories = matrixInfo.columns.subcategories.map((s, index) =>
            <th Style={"background-color: " + s.bg_color + "; color:" + s.color + ";"} > {s.name}</th>
        )

        second_subcategories = matrixInfo.rows.subcategories.map((s, i) =>
            <tr>
                {i == 0 ?
                    <td className='col-rotate' rowspan="3"><div Style={'text-transform: uppercase;transform: rotatez(270deg);  max-width: 110px;background-color: ' + matrixInfo.rows.bg_color + '; color:' + matrixInfo.rows.color}>{matrixInfo.rows.name}</div></td>
                    : ''}
                <td className='' Style={"background-color: " + s.bg_color + "; color: " + s.color + ";box-shadow: 0px 0px 2px black;"} colSpan={2}>{s.name}</td>
                {matrixInfo.matrix[i].map((users, i2) =>
                    <td className="p-10" Style={"background-color: " + users.bg_color + "; color:" + users.color + ";text-align: justify; min-width:100px;box-shadow: 0px 0px 2px black;"}>
                        {users.value.map((u, index) =>
                            <React.Fragment>
                                <span className="book">
                                    {index < 20 ? u + "; " : (index == 20 ? u + '...' : '')}
                                </span>
                            </React.Fragment>)}
                        {users.value && users.value.length >= 20 ? <div className="pointer bold mt-10 view_more_button" onClick={() => openViewMore(users, i, i2)}>View more</div> : ''}
                    </td>
                )}
            </tr>

        )
    }

    const openViewMore = (users, first_index, second_index) => {
        console.log(first_index)
        console.log(second_index)
        setFistParameterModalTitle(matrixInfo.columns.name + ': ' + matrixInfo.columns.subcategories[first_index].name)
        setSecondParameterModalTitle(matrixInfo.rows.name + ': ' + matrixInfo.rows.subcategories[second_index].name)
        setUsers(users)
        setOpenModal(true)
    }

    const customStylesModal = {
        content: {
            "z-index": 8,
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };


    const hadleExportPdf = () => {
        if (!exportingPdf && matrixInfo.is_populated) {
            setExportingPdf(true)
            ts.exportTalentMatrix('pdf', props.filters).finally(() => {
                setExportingPdf(false)
            })
        }
    }

    const hadleExportXlsx = () => {
        if (!exportingXlsx && matrixInfo.is_populated) {
            setExportingXlsx(true)
            ts.exportTalentMatrix('xlsx', props.filters).finally(() => {
                setExportingXlsx(false)
            })
        }
    }


    return (
        <div className="grid w-100">
            <Modal
                ariaHideApp={false}
                isOpen={openModal}
                onRequestClose={() => setOpenModal(false)}
                style={customStylesModal}
            >
                <div>
                    <div className="flex">
                        <div className="bold">
                            {fistParameterModalTitle}
                            <br />
                            {secondParameterModalTitle}
                        </div>
                        <div className="modal_users_close">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#000" class="bi bi-x-circle-fill pointer" viewBox="0 0 16 16" onClick={() => setOpenModal(false)}>
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                            </svg>
                        </div>
                    </div>
                    <div className="modal_users_container">
                        {users.value ? users.value.map((u) =>
                            <div>
                                <div className="book modal_user_line">{u}</div>

                            </div>) : ''}
                    </div>
                </div>
            </Modal >
            <h2 className="mt-30"> Map of Names </h2>
            <div>
                <div className="export_buttons_container flex mt--30">
                    <div className={!exportingPdf && matrixInfo.is_populated ? "flex export_button mr-10" : "flex export_button_disabled mr-10"} onClick={() => hadleExportPdf('pdf')}>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#000" class="bi bi-filetype-pdf" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z" />
                            </svg>
                        </div>
                    </div>
                    <div className={!exportingXlsx && matrixInfo.is_populated ? "flex export_button mr-10" : "flex export_button_disabled mr-10"} onClick={() => hadleExportXlsx('xls')}>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#000" class="bi bi-filetype-xlsx" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM7.86 14.841a1.13 1.13 0 0 0 .401.823q.195.162.479.252.284.091.665.091.507 0 .858-.158.355-.158.54-.44a1.17 1.17 0 0 0 .187-.656q0-.336-.135-.56a1 1 0 0 0-.375-.357 2 2 0 0 0-.565-.21l-.621-.144a1 1 0 0 1-.405-.176.37.37 0 0 1-.143-.299q0-.234.184-.384.188-.152.513-.152.214 0 .37.068a.6.6 0 0 1 .245.181.56.56 0 0 1 .12.258h.75a1.1 1.1 0 0 0-.199-.566 1.2 1.2 0 0 0-.5-.41 1.8 1.8 0 0 0-.78-.152q-.44 0-.777.15-.336.149-.527.421-.19.273-.19.639 0 .302.123.524t.351.367q.229.143.54.213l.618.144q.31.073.462.193a.39.39 0 0 1 .153.326.5.5 0 0 1-.085.29.56.56 0 0 1-.255.193q-.168.07-.413.07-.176 0-.32-.04a.8.8 0 0 1-.249-.115.58.58 0 0 1-.255-.384zm-3.726-2.909h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415H1.5l1.24-2.016-1.228-1.983h.931l.832 1.438h.036zm1.923 3.325h1.697v.674H5.266v-3.999h.791zm7.636-3.325h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415h-.861l1.24-2.016-1.228-1.983h.931l.832 1.438h.036z" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>

            {
                matrixInfo ?
                    <table className='details_table bold'>
                        <tr>
                            <th className='empty_cell' colspan="3" rowspan="2"></th>
                            <th className='first_parameter' Style={"background-color: " + matrixInfo.columns.bg_color + "; color:" + matrixInfo.columns.color + ";"} colspan="3" >{matrixInfo.columns.name}</th>
                        </tr>
                        <tr>
                            {first_subcategories}
                        </tr>

                        {second_subcategories}
                    </table>
                    : ''
            }
        </div >
    )
}