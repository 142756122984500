import './App.scss';
import MyRoutes from './MyRoutes';
import { BrowserRouter } from "react-router-dom";
function App() {
  return (
    <div className='bg'>
      <BrowserRouter basename={window.location.hostname === 'hrintegration.globalbit.gucci' ? '/talenttool/' : ''}
     /*basename={window.location.hostname !== 'retailtrainingcalendar.gucci.cn'
     && window.location.hostname !== 'retailtrainingcalendar.gucci.com'
     && window.location.hostname !== 'lucenewscalendar.gucci.com'
     && window.location.hostname !== 'lucenewscalendar.gucci.cn'
     && window.location.hostname !== 'localhost' ? '/digital_calendar/' : ''}
     */>
        <MyRoutes />
      </BrowserRouter>
    </div>
  );
}

export default App;
